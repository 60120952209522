import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import "../../demo/demo.css";
import auth from '../../services/auth';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import Button from "../CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import {Pie} from 'react-chartjs-2';

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import DateRange from "@material-ui/icons/DateRange";


import swal from 'sweetalert';
import {Bar} from 'react-chartjs-2';
import {Line} from 'react-chartjs-2';
import "react-datepicker/dist/react-datepicker.css";
import checatoken from '../checatoken';
import moment from 'moment-timezone';



export const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  export const numberFormat2 = (value) =>
  new Intl.NumberFormat('pt-BR').format(value);


class CompVendasHoje extends Component {

    constructor (props) {
        super(props)
        const brasilTime = moment.tz(new Date(), "America/Sao_Paulo");

        this.state = {startDate: new Date(),
                    };
            
              this.handleChange = this.handleChange.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {endDate: brasilTime.toDate(),
                 startDate: brasilTime.startOf('day').toDate(),
                 datapie: [],
                 Titulo: {
                    valorTotalComb: 0,
                    periodo: '',
                    unidade: '',
                    qtdeTotalComb:0,
                    lblVendacomb:'',
                    ticketMedio:0,
                    qtdeAbastecimentos:0,
                    totalCusto:0,
                    lucro:0,
                    markup:0

                  },
                    };
            
              this.handleChangef = this.handleChangef.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);
    }
      

    async DashboardBarChart () {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/unid/acum7dias', {
            headers: {token: chave}
        }).then( res => {
            this.setState({ dataline :  {
                labels: res.data.chart7x7[0].labels,
                datasets: res.data.chart7x7[1].datasets,
                options: res.data.chart7x7[2].options
                                        } 
                                    })
          }
        );
        
    };

    async CombustivelDia () {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('unid/GraficoLinhaComb', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ DataGraficoLinhaComb :  {
                                labels: res.data.GraficoLinhaComb[0].labels,
                                datasets: res.data.GraficoLinhaComb[1].datasets,
                                options: res.data.GraficoLinhaComb[2].options
                                                        } 
                                                    })                            
                        }
        )   
        
    };

    async CombustivelHora () {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('unid/GraficoHoraComb', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ GraficoHoraComb :  {
                                labels: res.data.GraficoHoraComb[0].labels,
                                datasets: res.data.GraficoHoraComb[1].datasets,
                                options: res.data.GraficoHoraComb[2].options
                                                        } 
                                                    })                            
                        }
        )   
        
    };

    async getgrafcombpordia(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('unid/grafcombpordia', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ datalbar :  {
                                labels: res.data.grafcombpordia[0].labels,
                                datasets: res.data.grafcombpordia[1].datasets,
                                options: res.data.grafcombpordia[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }
   
    
    
    // handleChange(date) {
    //         this.setState({ startDate: date })
    //         console.log(date);
    //         console.log(this.state.startDate);
  
    // }

    handleChange = (date) => {
            this.setState({ startDate: date })
            console.log(this.state.startDate);
};

    handleChangef(date) {
        this.setState({ endDate: date })

    }

  
      handleSubmit(e) {
        e.preventDefault();
        this.getTitulo(); 
        this.getDadosUnidade();
        this.getgrafcombpordia();
        this.CombustivelDia();
        this.CombustivelHora();
        this.getcombhojePizza();
       
      }





    async componentDidMount() {
        await checatoken();
        this.getVendeuHoje();
        this.getTitulo();
        this.getDadosUnidade();
        this.getgrafcombpordia();
        this.DashboardBarChart();
        this.CombustivelDia();
        this.CombustivelHora();
        this.getcombhojePizza();
 //      swal("Seja bem-vindo!", "Você está visualizando  "+this.state.data.unidOpe+" !", "success");        
       
    };


    async getTitulo(){
        const chave = localStorage.getItem('lukos-token');
        const response = await auth.get('unid/combtitulo', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        });
        console.log(response.data);
        this.setState({ Titulo: response.data });
    }


    async getcombhojePizza(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/unid/combhojePizza', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ datapie :  {
                                labels: res.data.vendacomb[0].labels,
                                datasets: res.data.vendacomb[1].datasets,
                                options: res.data.vendacomb[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }    

    async getDadosUnidade() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('unid/combhoje', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
            const vendas = res.data;
            let pComb = [];
            let pqtde = [];
            console.log(res.data);
            if (res.data !== '') {
                vendas.forEach(element => {
                    pComb.push(element.Comb);
                    pqtde.push(element.qtde); 
                    });
            }
        this.setState({
            data: {
                labels: pComb,
                datasets: [
                    {
                        label:'Venda combustivel ',
                        data: pqtde,
                         backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)', 
                            'rgba(255, 99, 132, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255, 99, 132, 0.2)'
                        ],                        
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                        hoverBorderColor: 'rgba(255,99,132,1)',
                        options: {
                                    responsive: true,
                                    display: false
                                  }        
                    }
                ]
            }
        })
  })
  .catch(function (error) {
        swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
    })  
};

    // async grafico2(){
    //     const chave = localStorage.getItem('lukos-token');
    //     await auth.get('/testachart', {
    //         headers: {
    //             token: chave
    //         }
    //     }).then( res => {
    //         this.setState({ grafico : res.data});
    //         console.log(res.data);
    //     });
    //     // this.setState({ dataline: response.data });        

    // };

      
    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return(<Redirect to="/login" />)
          }
          else
          {

        
        return (<div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>date_range</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Selecione o período</h6>
                        </CardHeader>
                        <CardBody>
                        <GridContainer> 
                        <GridItem xs={12} sm={6} md={6}>
                        <div>
                            <label>Data Inicial: </label>
                                <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="startDate"
                                    id="startDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    input="false"
                                    defaultValue = {this.state.startDate}
                                    onChange={ this.handleChange }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>                                
                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                         <div>
                            <label>Data Final : </label>
                            <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="endDate"
                                    id="endDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    defaultValue = {this.state.endDate}
                                    onChange={ this.handleChangef }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>    
                                {/* <DatePicker
                                name="endDate"
                                id="endDate"
                                selected={ this.state.endDate }
                                onChange={ this.handleChangef}
                                /> */}
                        </div> 
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <br></br>
                        <Button color="primary" round onClick={ this.handleSubmit}>

                                Filtrar
                                
                            </Button>
                        </GridItem>
                        </GridContainer>
                        </CardBody>
                        <CardFooter>

                         </CardFooter>
                    </Card>
                    </GridItem>
               
          
                    <GridItem xs={12} sm={3} md={3} >

                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Total Vendido</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                               {numberFormat(this.state.Titulo.valorTotalComb)} 
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                                <DateRange />
                                Valor total vendido
                        </CardFooter>
                    </Card>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3} >
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <CardIcon color="danger">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Total Custo</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                               {numberFormat(this.state.Titulo.totalCusto)} 
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                                <DateRange />
                                Custo total da venda
                        </CardFooter>
                    </Card>               
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3} >                    
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <Icon>add_shopping_cart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Litros Vendidos</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                               {numberFormat2(this.state.Titulo.qtdeTotalComb)} 
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                                <DateRange />
                                Valor total vendido
                        </CardFooter>
                    </Card> 
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3} >
                    
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>add_shopping_cart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Lucro</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                               {numberFormat2(this.state.Titulo.lucro)} 
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                                <DateRange />
                                Vendas - Custo
                        </CardFooter>
                    </Card>                
          
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3} >
                        <Card>
                            <CardHeader color="rose" stats icon>
                                <CardIcon color="rose">
                                    <Icon>attach_money</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Abastecimentos</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                {numberFormat2(this.state.Titulo.qtdeAbastecimentos)} 
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                    <DateRange />
                                    Quantidade de Abastecimentos
                            </CardFooter>
                        </Card>
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} >
                        <Card>
                            <CardHeader color="primary" stats icon>
                                <CardIcon color="primary">
                                    <Icon>attach_money</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Markup</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                {numberFormat2(this.state.Titulo.markup)} 
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                    <DateRange />
                                    Markup
                            </CardFooter>
                        </Card>                  
                    </GridItem>


                    <GridItem xs={12} sm={3} md={3} >
                        <Card>
                            <CardHeader color="danger" stats icon>
                                <CardIcon color="danger">
                                    <Icon>sentiment_satisfied</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Ticket Médio</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                {numberFormat(this.state.Titulo.ticketMedio)} 
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                    <DateRange />
                                    Valor Médio Abastecido
                            </CardFooter>
                        </Card>              
                    </GridItem>                    

                    {/* <GridItem xs={12} sm={3} md={3} >
                        <Card>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning">
                                    <Icon>sentiment_satisfied</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Total Vendido R$ 1.152.760,15</h6>
                                <h6>Custo Operacional R$ 111.385,78</h6>
                                <h6>Total Combustivel (94,1%) R$ 1.088.359,54 </h6>
                                <h6>Litros 231.178</h6>
                                <h6>Custo Operacional por Litro</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                {(0.4549)} 
                                </h3>                                

                            </CardHeader>
                            <CardFooter stats>
                                    <DateRange />
                                    Valor base do mês anterior
                            </CardFooter>
                        </Card>              
                    </GridItem>                     */}

                    <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Combustivel vendido ({this.state.Titulo.periodo}) </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                        <Bar
                        data={this.state.data} 
                        height={300} 
                        options={{
                            maintainAspectRatio: true
                        }}
                        /> 
                    </div>
                        </CardBody>
                        <CardFooter>
                               valores de referência em litros
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Acumulado por dia </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                        <Bar data={this.state.datalbar}  height={300}  />
                    </div>
                        </CardBody>
                        <CardFooter>
                               valores de referência em litros.
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Vendas por combustivel  </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                        <Line data={this.state.DataGraficoLinhaComb}  height={300}   options={{legend: { display: true}}} />
                    </div>
                        </CardBody>
                        <CardFooter>
                               valores de referência em litros 
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Acumulado 7x7  </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                        <Line data={this.state.dataline}  height={300}   options={{legend: { display: false}}} />
                    </div>
                        </CardBody>
                        <CardFooter>
                               valores de referência em litros - O filtro não altera este gráfico.
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Vendas por hora - Automação  </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                        <Line data={this.state.GraficoHoraComb}  height={300}   options={{legend: { display: true}}} />
                    </div>
                        </CardBody>
                        <CardFooter>
                               valores de referência em litros 
                        </CardFooter>
                    </Card>
                </GridItem>    
                <GridItem xs={12} sm={6} md={4}>
                <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">% de combustivel</h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                            <Pie data={this.state.datapie} height={300}   options={{legend: { display: true}, 
                        tooltips: {
                            callbacks: {
                              label: function(tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                var total = meta.total;
                                var currentValue = dataset.data[tooltipItem.index];
                                var percentage = parseFloat((currentValue/total*100).toFixed(1));
                                return currentValue + ' (' + percentage + '%)';
                              },
                              title: function(tooltipItem, data) {
                                return data.labels[tooltipItem[0].index];
                              }
                            }
                        }
                        }}/>
                    </div>
                        </CardBody>
                        <CardFooter>
                               Valores de referência: indicação de recebimento no cupom.
                        </CardFooter>
                    </Card>     

                    </GridItem>    
            </GridContainer>
        </div>
        )}

    };


}


export default CompVendasHoje;