import React, { Component } from 'react';
import auth from '../../services/auth';
import history from "../../history";


export default class Unidade extends Component {
  constructor() {
    super();
    this.state = {
      UnidSel: '',
      _unidade: [],
    }
  }

  Toolbar() {
    return (
      <div>
        Unidade
      </div>
    );
  }

  async getDadosSel() {
    const chave = localStorage.getItem('lukos-token');
    const response = await auth.get('/unid/dados', {
      headers: {
        token: chave
      }
    });
    this.setState({ UnidSel: response.data.unidOpe });
  }

  async componentDidMount() {
    await this.getunidade();
    await this.getDadosSel();
  }

  async getunidade() {
    const chave = localStorage.getItem('lukos-token');
    const response = await auth.get('/users/unidades', {
      headers: {
        token: chave
      }
    });
    this.setState({ _unidade: response.data });
  }

  async SetaUnidade(id) {
    const chave = localStorage.getItem('lukos-token');
    const response = await auth.get(`/unid/SetaUnid/'${id}`, {
      headers: {
        token: chave
      }
    });
    localStorage.setItem('lukos-token', response.data.token);
    history.push('/');

  }

  render() {
    return (<div>
      { this.state._unidade.map(unid => (
        <li className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root makeStyles-dropdownItem-78 MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button" 
            tabindex="0" 
            role="menuitem" 
            aria-disabled="false" 
            onClick={() => this.SetaUnidade(unid.idUnidadeOperacional)} 
            key={unid.idUnidadeOperacional} >
                {unid.UnidadeOperacional}
            <span classNa="MuiTouchRipple-root">
            </span>
          </li>
      )
      )

      }
    </div>
    );
  }
};
