import React, { Component } from 'react';
import auth from '../../services/auth';
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import {Pie} from 'react-chartjs-2';

function formatDate(dateOrMoment) {
    if (dateOrMoment._isAMomentObject) {
        return dateOrMoment.format('DD/MM/YYYY');
    } else {
        const day = String(dateOrMoment.getDate()).padStart(2, '0');
        const month = String(dateOrMoment.getMonth() + 1).padStart(2, '0');
        const year = dateOrMoment.getFullYear();

        return `${day}/${month}/${year}`;
    }
} 

class Compmeiopagto extends Component {


    state = {
        datapie : { labels: [],
            datasets: [],
            options: []},
    }

    async componentDidMount() {
        const vAcesso = localStorage.getItem('acesso'); 
        if (vAcesso === '1'){
            await this.getgrafcombpordia();
        }
    };    


    async getgrafcombpordia(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/unid/getmeiopagamentohoje', {
            headers: {token: chave,
                DataIni: formatDate(this.props.startDate),
                DataFim: formatDate(this.props.endDate)}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ datapie :  {
                                labels: res.data.meiopgtohoje[0].labels,
                                datasets: res.data.meiopgtohoje[1].datasets,
                                options: res.data.meiopgtohoje[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }    

    render() {

        return (<div>
                <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Meio de pagamento </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                            <Pie data={this.state.datapie} height={215}   options={{legend: { display: false}, 
                        tooltips: {
                            callbacks: {
                              label: function(tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                var total = meta.total;
                                var currentValue = dataset.data[tooltipItem.index];
                                var percentage = parseFloat((currentValue/total*100).toFixed(1));
                                return currentValue + ' (' + percentage + '%)';
                              },
                              title: function(tooltipItem, data) {
                                return data.labels[tooltipItem[0].index];
                              }
                            }
                        }
                        }}/>
                    </div>
                        </CardBody>
                        <CardFooter>
                               Valores de referência: indicação de recebimento no cupom.
                        </CardFooter>
                    </Card>            
        </div>
        )

    }
}

export default Compmeiopagto;