//import DashboardIcon from "@material-ui/icons/Dashboard";
import DashboardLukos from "./views/Dashboard/DashLukos.js";
//import EvStation from '@material-ui/icons/EvStation';
import VendasHoje from "views/Dashboard/vendashoje";
import TrocaPreco from "components/DashBoard/TrocaPreco";
//import LocalGasStation from '@material-ui/icons/LocalGasStation';
import posicaotanque from "components/DashBoard/PosicaoTanques";
//import Battery30 from '@material-ui/icons/Battery30';
import VendasProduto from "views/Dashboard/VendaProduto";
//import Person from "@material-ui/icons/Person";
import ComparativoVendas from "views/Dashboard/ComparativoVendas";
import ContasReceber from "./components/DashBoard/ContaReceber.js";
//import LibraryBooks from "@material-ui/icons/LibraryBooks";
import ContasPagar from "./components/DashBoard/ContaPagar.js";
import Metas from "./components/DashBoard/Metas.js";
//import BubbleChart from "@material-ui/icons/BubbleChart";
import Estoque from "./components/DashBoard/estoqueproduto.js";
//import Language from "@material-ui/icons/Language";
import EstoqueTanques from "./components/DashBoard/EstoqueTanque.js";
import Top10Prod from "./components/DashBoard/top10prod.js";
//import LocationOn from "@material-ui/icons/LocationOn";
import Top10Func from "./components/DashBoard/top10func.js";
//import Unarchive from "@material-ui/icons/Unarchive";
import resmensal from "./components/DashBoard/ResultadoMensal";
import PrevisaoTanque from "components/DashBoard/previsaotanque";
import ResumoCaixa from "views/Dashboard/ResumoCaixa.js";
import DetalhesCaixa from "components/DashBoard/DetalhesCaixa.js";
import ResumoUnidade from "./components/DashBoard/ResumoUnidade.js";
//import CompFidelidade from "components/DashBoard/compcfidelidade.js";
import Fidelidade from "views/Dashboard/Fidelidade.js";


// var dashRoutes2 = require('./Menu.json');


const App = ()  => {

   console.log('Fim do log')
// const chave = localStorage.getItem('lukos-token');
  
const retorno = JSON.parse(localStorage.getItem('Menu'));

// localStorage.setItem('Menu', JSON.stringify(returno));     
// console.log('Retorno');
 console.log(retorno);
if (retorno !== null) {
for (let i = 0; i < retorno.length; i++) {
  if (retorno[i].component === "DashboardLukos")   { retorno[i].component = DashboardLukos; }
  if (retorno[i].component === "ComparativoVendas") {retorno[i].component = ComparativoVendas; }
  if (retorno[i].component === "Metas") {retorno[i].component = Metas; }
  if (retorno[i].component === "Top10Func") {retorno[i].component = Top10Func; }
  if (retorno[i].component === "ResumoCaixa") {retorno[i].component = ResumoCaixa; }
  if (retorno[i].component === "ResumoUnidade") {retorno[i].component = ResumoUnidade; }
  if (retorno[i].component === "DetalhesCaixa") {retorno[i].component = DetalhesCaixa; }
  if (retorno[i].component === "Fidelidade") {retorno[i].component = Fidelidade; }
  if (retorno[i].hasOwnProperty('views')) {
    for (let j = 0; j < retorno[i].views.length; j++) {
      if (retorno[i].views[j].component === "ContasReceber") {retorno[i].views[j].component = ContasReceber; }
      if (retorno[i].views[j].component === "ContasPagar") {retorno[i].views[j].component = ContasPagar; }
      if (retorno[i].views[j].component === "VendasProduto") {retorno[i].views[j].component = VendasProduto; }
      if (retorno[i].views[j].component === "Top10Prod") {retorno[i].views[j].component = Top10Prod; }
      if (retorno[i].views[j].component === "Estoque") {retorno[i].views[j].component = Estoque; }
      if (retorno[i].views[j].component === "VendasHoje") {retorno[i].views[j].component = VendasHoje; }
      if (retorno[i].views[j].component === "TrocaPreco") {retorno[i].views[j].component = TrocaPreco; }
      if (retorno[i].views[j].component === "posicaotanque") {retorno[i].views[j].component = posicaotanque; }
      if (retorno[i].views[j].component === "EstoqueTanques") {retorno[i].views[j].component = EstoqueTanques; }
      if (retorno[i].views[j].component === "resmensal") {retorno[i].views[j].component = resmensal; }
      if (retorno[i].views[j].component === "PrevisaoTanque") {retorno[i].views[j].component = PrevisaoTanque; }

            
    }
  }

  }

  return retorno;       
} else {
  const  returno =
    [
        { "path": "/dashboard",  "name": "Dashboard",  "rtlName": "",  "icon": "dashboard",  "component": DashboardLukos,  "layout": "/admin" }
    ]   ;

  return returno;     
}
}

var dashRoutes =  App();
 
// var dashRoutes2 = 
// [
//   {
//     path: "/dashboard",
//     name: "Dashboard",
//     rtlName: "لوحة القيادة",
//     icon: DashboardIcon,
//   //  component: Dashboard,
//     component: DashboardLukos,
//     layout: "/admin"
//   },
 
//   {
//     path: "/ComparativoVendas",
//     name: "Comparativo Vendas",
//     rtlName: "قائمة الجدول",
//     icon: "content_paste",
//     component: ComparativoVendas,
//     layout: "/admin"
//   },  
//   {
//     path: "/ResumoCaixa",
//     name: "Resumo de Caixa",
//     rtlName: "قائمة الجدول",
//     icon: "add_business",
//     component: ResumoCaixa,
//     layout: "/admin"
//   },      
//   {
//     path: "/ResumoUnidade",
//     name: "Resumo Unidade",
//     rtlName: "قائمة الجدول",
//     icon: "add_business",
//     component: ResumoUnidade,
//     layout: "/admin"
//   },      
//   {
//     path: "/Fidelidade",
//     name: "Fidelidade",
//     rtlName: "قائمة الجدول",
//     icon: "add_business",
//     component: CompFidelidade,
//     layout: "/admin"
//   },      
//   {
//     path: "/Metas",
//     name: "Metas2",
//     rtlName: "الرموز",
//     icon: BubbleChart,
//     component: Metas,
//     layout: "/admin"
//   },  


//   {
//     path: "/top10Funcionario",
//     name: "Top 10 - Funcionários",
//     rtlName: "إخطارات",
//     icon: Unarchive,
//     component: Top10Func,
//     layout: "/admin"
//   },     
//   {
//     path: "/ResMensal",
//     name: "Resultado Mensal",
//     rtlName: "إخطارات",
//     icon: "timeline",
//     component: resmensal,
//     layout: "/admin"
//   },     
//   {
//     collapse: true,
//     name: "Financeiro",
//     rtlName: "صفحات",
//     icon: "request_quote",
//     state: "FinanceiroCollapse",
//     views: [
//       {
//         path: "/ContasReceber",
//         name: "Contas Receber",
//         rtlName: "طباعة",
//         icon: LibraryBooks,
//         component: ContasReceber,
//         layout: "/admin"
//       },
//       {
//         path: "/ContasPagar",
//         name: "Contas Pagar",
//         rtlName: "طباعة",
//         icon: LibraryBooks,
//         component: ContasPagar,
//         layout: "/admin"
//       },        
//     ]
//   },

//   {
//     collapse: true,
//     name: "Produtos",
//     rtlName: "صفحات",
//     icon: "storefront",
//     state: "ProdutosCollapse",
//     views: [
//       {
//         path: "/vendaproduto",
//         name: "Venda Produtos",
//         rtlName: "ملف تعريفي للمستخدم",
//         icon: Person,
//         component: VendasProduto,
//         layout: "/admin"
//       },   
//       {
//         path: "/top10prod",
//         name: "Top 10 - Produtos",
//         rtlName: "خرائط",
//         icon: LocationOn,
//         component: Top10Prod,
//         layout: "/admin"
//       },  
//       {
//         path: "/estoque",
//         name: "Estoque",
//         rtlName: "پشتیبانی از راست به چپ",
//         icon: Language,
//         component: Estoque,
//         layout: "/admin"
//       },       
//     ]

//   },

//   {
//     collapse: true,
//     name: "Combustível",
//     rtlName: "صفحات",
//     icon: "local_gas_station",
//     state: "CombustivelCollapse",
//     views: [
//       {
//           path: "/vendashoje",
//           name: "Vendas de Combustivel",
//           rtlName: "لوحة القيادة",
//           icon: EvStation,
//           component: VendasHoje,
//           layout: "/admin"
//       },
//       {
//         path: "/trocapreco",
//         name: "Preço Combustível",
//         rtlName: "ملف تعريفي للمستخدم",
//         icon: LocalGasStation,
//         component: TrocaPreco,
//         layout: "/admin"
//       }, 
//       {
//         path: "/posicaotanque",
//         name: "Nível dos tanques",
//         rtlName: "ملف تعريفي للمستخدم",
//         icon: Battery30,
//         component: posicaotanque,
//         layout: "/admin"
//       },  
//       {
//         path: "/tanques",
//         name: "Estoque Tanques",
//         rtlName: "پشتیبانی از راست به چپ",
//         icon: Language,
//         component: EstoqueTanques,
//         layout: "/admin"
//       },            
//       {
//         path: "/previsaotanque",
//         name: "Previsão Tanques",
//         rtlName: "پشتیبانی از راست به چپ",
//         icon: LocalGasStation,
//         component: PrevisaoTanque,
//         layout: "/admin"
//       }            
//     ]
//   },
//   {
//     path: "/detalhecaixa/:id",
//     name: "",
//     rtlName: "قائمة الجدول",
//     icon: "",
//     component: DetalhesCaixa,
//     layout: "/admin",
//     visible: false
//   }
// ];

export default dashRoutes;
