import React from 'react';


import {  Redirect } from "react-router-dom";
import CompVendasHoje from '../../components/DashBoard/compvendashoje';


export default function VendasHoje() {
      const isLogged = !!localStorage.getItem('lukos-token');




      if (isLogged === false) {
        return(<Redirect to="/login" />)
      }
      else
      {  return (        
                    <div>
                      <CompVendasHoje />
                  </div>
                  );
  };
}
