import React, { Component } from 'react';
import auth from '../../services/auth';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardFooter from "../Card/CardFooter.js";
import checatoken from '../checatoken';
import CardBody from "../Card/CardBody.js";
import {Line} from 'react-chartjs-2';

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";


import Compmeiopagto from "./cardmeiopagto";
import ValorHoraChart from "./valorhora";
import QtdeHoraChart from "./QtdeHora";
import SweetAlert from "react-bootstrap-sweetalert";
import swal from 'sweetalert';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Button from "../CustomButtons/Button.js";

import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";


export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);

export const numberFormat2 = (value) =>
    new Intl.NumberFormat('pt-BR').format(value);


function formatDate(dateOrMoment) {
    if (dateOrMoment._isAMomentObject) {
        return dateOrMoment.format('DD/MM/YYYY');
    } else {
        const day = String(dateOrMoment.getDate()).padStart(2, '0');
        const month = String(dateOrMoment.getMonth() + 1).padStart(2, '0');
        const year = dateOrMoment.getFullYear();

        return `${day}/${month}/${year}`;
    }
} 

class CompDashBoard extends Component {
    constructor(props) {
        super(props)
    this.state = {
        data: {
            idUnidOPe: '',
            unidOpe: '',
        },
        vendas: {
            ComissaoRet: 0,
            Cortesia: 0,
            Imposto: 0,
            Lucro: 0,
            MediaItensCupom: 0,
            QtdeCupons: 0,
            QtdeItens: 0,
            TicketMedio: 0,
            ValorCompra: 0,
            ValorSaida: 0,
            Markup: 0
        },
        DataVendaHoraLinha: { labels: [],
                            datasets: [],
                            options: []},
        DataVendaHoraqtde:  { labels: [],
            datasets: [],
            options: []},
        Carregando:true,
        shoje : '',
        startDate: new Date(),
        endDate: new Date(),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangef = this.handleChangef.bind(this);
    }


    handleChange(date) {
        this.setState({ startDate: date })

    }

    handleChangef(date) {
        this.setState({ endDate: date })

    }

    handleSubmit(e) {
        e.preventDefault();
        console.log(this.state.startDate);
        this.getVendeuHoje();
    }

    tick() {
        this.setState(state => ({
          seconds: state.seconds + 1
        }));
        this.Recarrega();
      }

    async hideAlert () {
        console.log('hide')
      };      
          

    async componentDidMount() {
        const vAcesso = localStorage.getItem('acesso'); 
        if (vAcesso === null){
            this.setState({ vAcesso : '0'})
            
        }

        if (vAcesso !== '0') {
            checatoken();
              this.getVendeuHoje();
              this.VendaHora();
              this.VendaHoraQtde();
              this.getshoje();
         
             console.log(vAcesso);
        } 
             
        if (vAcesso === '1') {
            this.setState({Carregando:false});

            //await this.getVendeuHoje();
        } 

        await this.ChecaAcesso();
        this.interval = setInterval(() => this.tick(), 20000);
        };


    async ChecaAcesso (){
        const vAcesso = localStorage.getItem('acesso'); 
        if (vAcesso !== '1') {
            localStorage.setItem('acesso', '1'); 
            window.location.reload(false);
        }
    }

    async getDadosUnidade() {
                const chave = localStorage.getItem('lukos-token');
                const response = await auth.get('/unid/dados', {
                headers: {
                    token: chave
                }
            });
            this.setState({ data: response.data });
    }

    async getVendeuHoje() {
                const chave = localStorage.getItem('lukos-token');
                console.log('Vendeu hoje')
                console.log(this.state.startDate)
                try {
                    const response = await auth.get('/unid/vendeuhoje', {
                        headers: {
                            token: chave,
                            DataIni: formatDate(this.state.startDate),
                            DataFim: formatDate(this.state.endDate)
                        }
                    });
                    this.setState({ vendas: response.data });
                } catch (error) {
                    console.error("Erro ao buscar dados:", error);
                    // Você pode adicionar mais lógica aqui para tratar o erro, como mostrar uma mensagem para o usuário.
                }
    }

    async VendaHora () {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/unid/VendaHoraLinha', {
            headers: {token: chave},
        }).then( res => {
                            console.log(res.data)
                            this.setState({ DataVendaHoraLinha :  {
                                labels: res.data.VendaHoraLinha[0].labels,
                                datasets: res.data.VendaHoraLinha[1].datasets,
                                options: res.data.VendaHoraLinha[2].options
                                                        } 
                                                    })                            
                        }
        )   
        
    };

    async VendaHoraQtde () {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/unid/VendaHoraqtde', {
            headers: {token: chave},
        }).then( res => {
                            console.log(res.data)
                            this.setState({ DataVendaHoraqtde :  {
                                labels: res.data.VendaHoraqtde[0].labels,
                                datasets: res.data.VendaHoraqtde[1].datasets,
                                options: res.data.VendaHoraqtde[2].options
                                                        } 
                                                    })                            
                        }
        )   
        
    };

    async getshoje() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/lukos/stemp', {
            headers: {token: chave},
        }).then( res => {
            this.setState({ shoje: res.data})
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };    

    async Recarrega(){
        //await this.getChamadosHoje();
         clearInterval(this.interval);
         await this.getVendeuHoje();
         await this.VendaHora();
         await this.VendaHoraQtde();
         await this.getshoje();
         //swal("Carregando um minuto.", "warning");
         this.interval = setInterval(() => this.tick(), 20000);
         //window.location.reload(false);
    }


    render() {
       const vsenha = this.state.shoje;
       let vbutton;
       if (this.state.Carregando) {
            return (<div>
                     <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Seja bem-vindo!"
                        showConfirm={false}
                        onConfirm={() => {
                            console.log('confirm');
                          }}
                    >
                        Estamos Carregando os dados, por favor aguarde.<br/>
                        O tempo pode variar de acordo com a sua conexão.
                    </SweetAlert>
                    </div>)

       }     
       else {
        if (vsenha !== 'S') {
            vbutton =  <CopyToClipboard text={this.state.shoje}
            onCopy={() => this.setState({copied: true})}>
            <Button color="primary" round >Copiar Senha Temporária</Button>     
            </CopyToClipboard>      
        }    

        return (<div>
            <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Total Vendido</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                                {numberFormat(this.state.vendas.ValorSaida)}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Valor total vendido
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>add_shopping_cart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Quantidade Cupom</h6>
                            <h3 className='makeStyles-cardTitle-91'>{this.state.vendas.QtdeCupons}  </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                quantidade de cupons emitidos
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="primary" stats icon>
                            <CardIcon color="primary">
                                <Icon>show_chart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Ticket Médio</h6>
                            <h3 className='makeStyles-cardTitle-91'>{numberFormat(this.state.vendas.TicketMedio)} </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <LocalOffer />
                                Valor médio do cupom
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardBody>
                        <div>
                            <h6>Vendas ultima hora R$</h6>
                            <Line data={this.state.DataVendaHoraLinha} height={70}   options={{legend: { display: false}}}/>
                        </div>
                        </CardBody>
                    </Card>            
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardBody>
                        <div>
                            <h6>Vendas ultima hora Qtde</h6>
                            <Line data={this.state.DataVendaHoraqtde} height={70}   options={{legend: { display: false}}}/>
                        </div>
                        </CardBody>
                    </Card>            
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>pie_chart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Markup</h6>
                            <h3 className='makeStyles-cardTitle-91'>{numberFormat2(this.state.vendas.Markup)}</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <Update />
                                Markup médio das vendas
                        </CardFooter>
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>production_quantity_limits</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Quantidade produtos</h6>
                            <h3 className='makeStyles-cardTitle-91'>{this.state.vendas.QtdeItens} </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <Update />
                                Quantidade total de produtos
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>sentiment_satisfied</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Média de itens</h6>
                            <h3 className='makeStyles-cardTitle-91'>{numberFormat2(this.state.vendas.MediaItensCupom)} </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <Update />
                                Média de produtos por cliente
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <CardIcon color="danger">
                                <Icon>sentiment_satisfied</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Valor de Compra</h6>
                            <h3 className='makeStyles-cardTitle-91'>{numberFormat(this.state.vendas.ValorCompra)}  </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <Update />
                                PMC dos produtos vendidos
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <Icon>monetization_on</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Lucro</h6>
                            <h3 className='makeStyles-cardTitle-91'>{numberFormat(this.state.vendas.Lucro)}  </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <Update />
                                Lucro obtido nas vendas
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <CardIcon color="danger">
                                <Icon>sentiment_dissatisfied</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Imposto</h6>
                            <h3 className='makeStyles-cardTitle-91'>{numberFormat(this.state.vendas.Imposto)}  </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <Update />
                                imposto previsto
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>people_alt</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Comissão</h6>
                            <h3 className='makeStyles-cardTitle-91'>{numberFormat(this.state.vendas.ComissaoRet)}  </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <Update />
                                comissão dos colaboradores
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="primary" stats icon>
                            <CardIcon color="primary">
                                <Icon>cloud_off</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Cortesia</h6>
                            <h3 className='makeStyles-cardTitle-91'>{numberFormat(this.state.vendas.Cortesia)}  </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <Update />
                                Cupons de cortesia
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <Compmeiopagto 
                     key={`${this.state.startDate}-${this.state.endDate}`} 
                     startDate={this.state.startDate} 
                     endDate={this.state.endDate} />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <QtdeHoraChart />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <ValorHoraChart />
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} >
                        <Card>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning">
                                    <Icon>sentiment_satisfied</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Selecione o período</h6>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <div>
                                            <label>Data Inicial: </label>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    //   timeFormat={false}
                                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                                    timeFormat={false}
                                                    name="startDate"
                                                    id="startDate"
                                                    locale="pt-br"
                                                    dateFormat="DD/MM/yyyy"
                                                    defaultValue={this.state.startDate}
                                                    onChange={this.handleChange}
                                                    inputProps={{ placeholder: "Date Picker Here" }}
                                                />
                                            </FormControl>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <div>
                                            <label>Data Final : </label>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    //   timeFormat={false}
                                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                                    timeFormat={false}
                                                    name="endDate"
                                                    id="endDate"
                                                    locale="pt-br"
                                                    dateFormat="DD/MM/yyyy"
                                                    defaultValue={this.state.endDate}
                                                    onChange={this.handleChangef}
                                                    inputProps={{ placeholder: "Date Picker Here" }}
                                                />
                                            </FormControl>
                                            {/* <DatePicker
                                name="endDate"
                                id="endDate"
                                selected={ this.state.endDate }
                                onChange={ this.handleChangef}
                                /> */}
                                        </div>
                                    </GridItem>

                                 
                                    <GridItem xs={12} sm={12} md={12}>
                                        <br></br>
                                        <Button color="primary" round onClick={this.handleSubmit}>

                                            Filtrar

                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

               {vbutton}                                                                             
            </GridContainer>
        </div>
        )
        }
    };


}


export default CompDashBoard;