import React, {  useState } from 'react'
import auth from '../services/auth';
import './login.css'
import history from '../history';
import Logo from './retaguarda_64.png';
import swal from 'sweetalert';


const Login = () => {
//class Login extends Component{

    const [myUser, setUser] = useState();

    const handleInputChange = ev => {
        let name = [ev.target.name];
        let value = ev.target.value;
        setUser(
            { ...myUser, [name] : value }
        );
    }


    const handleSubmit = ev => {
        localStorage.removeItem('lukos-token');
      //  localStorage.removeItem('Menu');
        localStorage.removeItem('acesso');
        ev.preventDefault();
        auth.post('/users/auth', {params: {
                                            Usuario: myUser,
                                            host :  window.location.href 
                                          } 
                                }

                )


        .then(res => {
            console.log(res);
             localStorage.setItem('lukos-token', res.data.token);
             SalvaMenu();
             
        })
        .catch(error => {
            console.log(error);
            swal("OPS!", "Usuário e/ou senha inválidos.", "error");
        })
    }

    const SalvaMenu = () => {
        const chave = localStorage.getItem('lukos-token');
        if (chave !== null){
            auth.get('/users/GetMenu', {
                headers: { token: chave },
            }).then(res => {
                localStorage.setItem('acesso', '0'); 
                localStorage.setItem('Menu', JSON.stringify(res.data)); 
                history.push('/admin/dashboard');
            })
                .catch(error => {
                    swal("OPS!", "Você não tem permissão para acessar este painel.", "error");
                })
        }
    }


    return (
        <div className="Login">
                <div className="wrapper fadeInDown">
                    <div id="formContent">
                        <div className="fadeIn first">
                        <img src={Logo}  alt="logo" />
                        <h3>Lukos Tecnologia</h3>
                        </div>
    
                        <form onSubmit={handleSubmit}>
                            <div className="col-10">
                                <input type="text" 
                                   id="login" 
                                   className="fadeIn second" 
                                   name="login" 
                                   placeholder="Usuário"
                                   onChange={handleInputChange} />
                            </div>
                            <div className="col-5">
                            <input type="password" 
                                   id="Senha" 
                                   className="fadeIn third" 
                                   name="Senha" 
                                   placeholder="Senha"
                                   onChange={handleInputChange}/>
    
                            <input type="submit" className="fadeIn fourth" value="Entrar"/>
                            </div>
                        </form>
    
                        <div id="formFooter">
                            <a className="underlineHover" href="https://lukos.com.br/">Lukos Soluções em Tecnologia</a>
                        </div> 
    
                    </div>
                </div>
    
    
        </div>
      );
};

export default Login;