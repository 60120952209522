import React from 'react';
import { Redirect } from "react-router-dom";
import CompDashBoard from '../../components/DashBoard/compdashboard';



export default function DashboardLukos() {
      const isLogged = !!localStorage.getItem('lukos-token');
      
      if (isLogged === false) {
        return(<Redirect to="/login" />)
      }
      else
      {  return (        
                    <div>
                      <CompDashBoard />
                  </div>
                  );
  };
}
