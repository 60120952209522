import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import auth from '../../services/auth';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardFooter from "../Card/CardFooter.js";
import Icon from "@material-ui/core/Icon";
import DateRange from "@material-ui/icons/DateRange";
import Heading from "components/Heading/Heading.js";
import CardBody from "../Card/CardBody.js";
import {Pie} from 'react-chartjs-2';
import {Bar} from 'react-chartjs-2';
import Table from "components/Table/Table.js";

export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);


export const DataFormat = (value) =>
    new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
        }).format(value);

export const numberFormat2 = (value) =>
    new Intl.NumberFormat('pt-BR').format(value);

class DetalheCaixa extends Component {

    state = {
        Caixa: {
            idCaixaAbertura: 0,
            DataHora: '',
            Turno: 0,
            Box: 0,
            idOperador: 0,
            DataEncerramento: '',
            TrocoAbertura: 0,
            TrocoEncerramento: 0,
            FlgTrocoCartao: '',
            DataAbertura:'',
            idUnidadeOperacional: 0,
            idEstoque: 0,
            FlgTrancado: '',
            Operador: '',
            UnidadeOperacional:'',
            StatusCaixa:'',
            TotalProdutos: 0,
            TotalServicos: 0,
            TotalRecebimentos: 0,
            TotalSuprimentos: 0,
            DescontoCupom: 0,
            TotalEntradas: 0,
            TotalPagamentos: 0,
            TotalSangrias: 0,
            TotalSaidas: 0,
            Diferenca: 0,
            QtdeCupom: 0,
            TotalCancelado: 0,
            QtdeCancelado: 0,
            TotalCombustivel: 0,
        },
        categorias : [],
        flags: {
            new: null
        },
        datapie : [],
        dataPieSangria: [],
        dataPieGrupo: [],
        TabelaDetProd: [],
        TabelaDetComb: [],
        CombustivelActive: false,
        ProdutoActive: false,
    }

    dataChange(ev) {
        let name = [ev.target.name];
        let value = ev.target.value;
        this.setState(prevState => ({
            data: { ...prevState.data, [name]: value }
        }))
    }



    async componentDidMount() {
        const { id } = this.props.match.params;
        const chave = localStorage.getItem('lukos-token');
        if (typeof id !== "undefined") {
            await auth.get('/resumocaixa/detalhe', {
                headers: {token: chave},
                params:   {ident : id}
            }).then( res => {
                console.log(res.data)
                this.setState({Caixa: res.data})
                    
                }).catch(function (error) {
                })  


                await auth.get('/resumocaixa/', {
                    headers: {token: chave},
                    params:   {ident : id}
                }).then( res => {
                                    console.log(res.data)
                                    this.setState({ datapie :  {
                                        labels: res.data.meiopgtohoje[0].labels,
                                        datasets: res.data.meiopgtohoje[1].datasets,
                                        options: res.data.meiopgtohoje[2].options
                                                                } 
                                                            })                            
                                }
                ).catch(function (error) {
                })  
       
                await auth.get('/resumocaixa/GetSangriasCaixa', {
                    headers: {token: chave},
                    params:   {ident : id}
                }).then( res => {
                                    console.log(res.data)
                                    this.setState({ dataPieSangria :  {
                                        labels: res.data.meiopgtohoje[0].labels,
                                        datasets: res.data.meiopgtohoje[1].datasets,
                                        options: res.data.meiopgtohoje[2].options
                                                                } 
                                                            })                            
                                }
                ).catch(function (error) {
                })  
 
                
                await auth.get('/resumocaixa/GetProdGrupoCaixa', {
                    headers: {token: chave},
                    params:   {ident : id}
                }).then( res => {
                                    console.log(res.data)
                                    this.setState({ dataPieGrupo :  {
                                        labels: res.data.ProdGrupoCaixa[0].labels,
                                        datasets: res.data.ProdGrupoCaixa[1].datasets,
                                        options: res.data.ProdGrupoCaixa[2].options
                                                                } 
                                                            })                        
                                }
                ).catch(function (error) {
                    
                })                                 

                await auth.get('/resumocaixa/GetProdtabelaCaixa', {
                    headers: {token: chave},
                    params:   {ident : id}
                }).then( res => {
                    this.setState({ ProdutoActive: true})
                    this.setState({ TabelaDetProd: res.data})
                        
                    }
                ).catch(error => {
                    console.error("Erro ao buscar dados:", error);
                });

                await auth.get('/resumocaixa/GetCombtabelaCaixa', {
                    headers: {token: chave},
                    params:   {ident : id}
                })
                .then( res => {
                    this.setState({ TabelaDetComb: res.data})
                    })
                .catch(error => { 
                    console.error("Erro ao buscar dados:", error);
                });
               
                  await auth.get('/resumocaixa/GetCombCaixa', {
                    headers: {token: chave},
                    params:   {ident : id}
                }).then( res => {
                                    console.log(res.data)
                                    this.setState({ CombustivelActive: true})
                                    this.setState({ datalbar :  {
                                        labels: res.data.CaixaCombLitros[0].labels,
                                        datasets: res.data.CaixaCombLitros[1].datasets,
                                        options: res.data.CaixaCombLitros[2].options
                                                                } 
                                                            })                            
                                }
                ).catch( error => {
                    console.error("Erro ao buscar dados:", error);
                });       
                
        } else {
            this.setState({ flags: { new: true } });
        }
    }

    render() {
        
        return (
            <div>
                 <Heading
                    textAlign="center"
                    title={"Caixa:"+this.state.Caixa.idCaixaAbertura+" - Operador:"+this.state.Caixa.Operador+" - Turno:"+this.state.Caixa.Turno+ " Data:"+this.state.Caixa.StatusCaixa}
                    category={"Unidade Operacional: "+ this.state.Caixa.UnidadeOperacional }
                />
            <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>production_quantity_limits</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Total Produtos</h6>
                            <h3> {numberFormat(this.state.Caixa.TotalProdutos)}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Valor total vendido
                        </CardFooter>
                    </Card>
                </GridItem>  
                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="info">
                                <Icon>local_gas_station_sharp</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Total Combustivel</h6>
                            <h3> {numberFormat(this.state.Caixa.TotalCombustivel)}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Valor total vendido
                        </CardFooter>
                    </Card>
                </GridItem>  
                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="info">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Total Serviços</h6>
                            <h3> {numberFormat(this.state.Caixa.TotalServicos)}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Valor total vendido
                        </CardFooter>
                    </Card>
                </GridItem>  
                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Total de Entradas</h6>
                            <h3> {numberFormat(this.state.Caixa.TotalEntradas)}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Valor total vendido
                        </CardFooter>
                    </Card>
                </GridItem>  
                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="warning">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Pagamentos</h6>
                            <h3> {numberFormat(this.state.Caixa.TotalPagamentos)}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Pagamentos / Despesas
                        </CardFooter>
                    </Card>
                </GridItem>  
                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="warning">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Sangrias</h6>
                            <h3> {numberFormat(this.state.Caixa.TotalSangrias)}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Sangrias realizadas
                        </CardFooter>
                    </Card>
                </GridItem>  
                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="danger">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Total de saídas</h6>
                            <h3> {numberFormat(this.state.Caixa.TotalSaidas)}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Sangrias realizadas
                        </CardFooter>
                    </Card>
                </GridItem>  
                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="primary">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Diferença de caixa</h6>
                            <h3> {numberFormat(this.state.Caixa.Diferenca)}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                                Saídas  - Entradas = Diferença
                        </CardFooter>
                    </Card>
                </GridItem>  

                <GridItem xs={12} sm={4} md={4}>
                <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Meio de pagamento </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                            <Pie data={this.state.datapie} height={455}   options={{legend: { display: true}, 
                        tooltips: {
                            callbacks: {
                              label: function(tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                var total = meta.total;
                                var currentValue = dataset.data[tooltipItem.index];
                                var percentage = parseFloat((currentValue/total*100).toFixed(1));
                                return currentValue + ' (' + percentage + '%)';
                              },
                              title: function(tooltipItem, data) {
                                return data.labels[tooltipItem[0].index];
                              }
                            }
                        }
                        }}/>
                    </div>
                        </CardBody>
                        <CardFooter>
                               Valores de referência: indicação de recebimento no cupom.
                        </CardFooter>
                    </Card>            
                    </GridItem>  

                    <GridItem xs={12} sm={4} md={4}>
                    <Card>
                        <CardHeader color="success">
                            <h4 className="makeStyles-cardTitleWhite-87">Sangrias realizadas </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                            <Pie data={this.state.dataPieSangria} height={455}   options={{legend: { display: true}, 
                        tooltips: {
                            callbacks: {
                              label: function(tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                var total = meta.total;
                                var currentValue = dataset.data[tooltipItem.index];
                                var percentage = parseFloat((currentValue/total*100).toFixed(1));
                                return currentValue + ' (' + percentage + '%)';
                              },
                              title: function(tooltipItem, data) {
                                return data.labels[tooltipItem[0].index];
                              }
                            }
                        }
                        }}/>
                    </div>
                        </CardBody>
                        <CardFooter>
                               Valores de referência: Sangrias indicadas no Caixa
                        </CardFooter>
                    </Card>            
                    </GridItem>  
                {this.state.ProdutoActive ?                     
                <GridItem xs={12} sm={4} md={4}>
                    <Card>
                        <CardHeader color="info">
                            <h4 className="makeStyles-cardTitleWhite-87">Produtos Por Grupo </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                            <Pie data={this.state.dataPieGrupo} height={455}   options={{legend: { display: true}, 
                        tooltips: {
                            callbacks: {
                              label: function(tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                var total = meta.total;
                                var currentValue = dataset.data[tooltipItem.index];
                                var percentage = parseFloat((currentValue/total*100).toFixed(1));
                                return currentValue + ' (' + percentage + '%)';
                              },
                              title: function(tooltipItem, data) {
                                return data.labels[tooltipItem[0].index];
                              }
                            }
                        }
                        }}/>
                    </div>
                        </CardBody>
                        <CardFooter>
                               Valores de referência: Total em R$ vendido no Grupo
                        </CardFooter>
                    </Card>            
                    </GridItem>  
                    : null }    
                    {this.state.ProdutoActive ?                     
                    <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="success">
                            <h4 className="makeStyles-cardTitleWhite-87">Produtos Vendidos </h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Produto", "Quantidade",  "Valor Total"]}
                            tableData={this.state.TabelaDetProd}
                        />
                        </CardBody>
                        <CardFooter>
                            Produtos vendidos no Turno
                        </CardFooter>
                    </Card>
                </GridItem>  
                : null }              

                {this.state.CombustivelActive ? 
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="warning">
                            <h4 className="makeStyles-cardTitleWhite-87">Combustivel Vendido </h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Produto", "Litros",  "Valor Total"]}
                            tableData={this.state.TabelaDetComb}
                        />
                        </CardBody>
                        <CardFooter>
                            combustivel vendido no turno
                        </CardFooter>
                    </Card>
                </GridItem>             
                 : null }                                
                 {this.state.CombustivelActive ? 
                        <GridItem xs={12} sm={6} md={6}>
                        <Card>
                            <CardHeader color="info">
                                <h4 className="makeStyles-cardTitleWhite-87">Venda de Combustivel Litros.  </h4>
                            </CardHeader>
                            <CardBody>
                            <Bar data={this.state.datalbar} options={{legend: { display: false}}} />
                            </CardBody>
                            <CardFooter>
                                Venda de Combustivel Litros.
                            </CardFooter>
                        </Card>
                    </GridItem>                
                 : null }                                
            </GridContainer>
            </div>   
            
        )
    }
}

export default withRouter(DetalheCaixa);
