import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import auth from '../../services/auth';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import CardIcon from "../Card/CardIcon.js";
import Button from "../CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Datetime from "react-datetime";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import swal from 'sweetalert';
//import swal from 'sweetalert2'
import checatoken from '../checatoken';
import CompUnidade from './compUnidade';
import CompUnidadeProd from './compUnidadeProduto';


export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);

export const numberFormat2 = (value) =>
    new Intl.NumberFormat('pt-BR').format(value);



class ResumoUnidade extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            _Unidades: [],
            _jsonunidades: [],
            TabelaPag: [],
            Dados: [],
            Teste: [],
            checkedB: false,
            endDate: new Date(),
            startDate: new Date(),
            ourResultsList: [],
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangef = this.handleChangef.bind(this);
    }

    estado = { 
        renderList: null 
      }
    handleChange(date) {
        this.setState({ startDate: date })

    }

    handleChangef(date) {
        this.setState({ endDate: date })

    }

    handleSubmit(e) {
        //swal('Carregando', 'aguarde enquanto a página é carregada', "error")
          swal({
             title: "Carregando",
             text: "Por favor aguarde.",
             type: 'success',
             buttons: false,
             timer: 3000,
         });    
         this.setState({ 
            renderList: false 
          }) 
          this.setState({ 
            ourResultsList: null 
          })           
          this.setState({_jsonunidades: []})
          
        this.GetUnidades2();
        swal.close();
    }

    handleChangeC = event =>
        this.setState({ checkedB: event.target.checked });

    async ChecaAcesso() {
        const vAcesso = localStorage.getItem('acesso');
        console.log('Acesso ')
        console.log(vAcesso);
        if (vAcesso !== '1') {
            localStorage.setItem('acesso', '1');
            window.location.reload(false);
        }
    }

    async componentDidMount() {
        this.setState({ 
            renderList: false 
            }) 
        await checatoken();
        // await this.GetUnidades();
      //  await this.GetUnidades2();
    };


    async GetUnidades2() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/metas/UnidadeData2', {
            headers: { token: chave },
            params: { dataIni: this.state.startDate, dataFim: this.state.endDate, Encerrado: this.state.checkedB }

        }).then(res2 => {
            console.log(res2);
            this.setState({ _jsonunidades: res2.data.resumos });
              })
            .catch(error => {
                console.log(error);
                swal("OPS!", "Não foi possível abrir as metas ", "error");
            })
    };


    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return (<Redirect to="/login" />)
        }
        else {
            return (
            <div>
                
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} >
                        <Card>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning">
                                    <Icon>sentiment_satisfied</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Selecione o período</h6>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <div>
                                            <label>Data Inicial: </label>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    //   timeFormat={false}
                                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                                    timeFormat={false}
                                                    name="startDate"
                                                    id="startDate"
                                                    locale="pt-br"
                                                    dateFormat="DD/MM/yyyy"
                                                    closeOnSelect="true"
                                                    input="false"
                                                    defaultValue={this.state.startDate}
                                                    onChange={this.handleChange}
                                                    inputProps={{ placeholder: "Date Picker Here" }}
                                                />
                                            </FormControl>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <div>
                                            <label>Data Final : </label>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    //   timeFormat={false}
                                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                                    timeFormat={false}
                                                    name="endDate"
                                                    id="endDate"
                                                    locale="pt-br"
                                                    dateFormat="DD/MM/yyyy"
                                                    closeOnSelect="true"
                                                    defaultValue={this.state.endDate}
                                                    onChange={this.handleChangef}
                                                    inputProps={{ placeholder: "Date Picker Here" }}
                                                />
                                            </FormControl>
                                            {/* <DatePicker
                                name="endDate"
                                id="endDate"
                                selected={ this.state.endDate }
                                onChange={ this.handleChangef}
                                /> */}
                                        </div>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkedB}
                                                    onChange={this.handleChangeC}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label="Separar Produtos por grupo"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <br></br>
                                        <Button color="primary" round onClick={this.handleSubmit}>

                                            Filtrar

                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                     {this.state._jsonunidades.map( (ans) => (
                                        

                                        <GridItem xs={12} sm={12} md={6} >
									<Card>
                                        <CardHeader color="success" stats icon>
                                            <CardIcon color="success">
                                                <Icon>pie_chart</Icon>
                                            </CardIcon>
                                            <br></br>
                                            <h3>{ans.unidade} - {ans.dataMov}</h3>
                                        </CardHeader>
                                        <CardBody>
                                 
                                            
                                        {/* {
                                            ans.combustiveis.map(d => (
                                                <tr>
                                                    <td>{d.combustivel}</td>
                                                    <td align ="center">{d.litros}</td>
                                                    <td align ="center">{d.unitario}</td>
                                                    <td align ="right">{d.totalVendido}</td>
                                                    <td align ="center">{d.pMC}</td>
                                                    <td align ="right">{d.custo}</td>
                                                    <td align ="right">{d.lucroBruto}</td>
                                                    <td align ="right">{d.margem}</td>
                                                </tr>

                                                    
                                            ))
                                        }                                             */}
                                           
                                        {/* {
                                            ans.combustiveis.map(d => (
                                            <div class="row">    
                                                <div class="col-sm-3">
                                                    {d.combustivel}
                                                </div>
                                                <div class="col-sm-3">
                                                    {d.totalVendido}
                                                </div>
                                            </div>
                                            ))
                                        } */}
                                        <h3>Combustível</h3>
                                        <h4>Venda: {ans.totalCombustivel} - Custo: {ans.custoCombustivel} = LucroBruto: {ans.lucroCombustivel}</h4>
                                        <h3>Produto</h3>
                                        <h4>Venda: {ans.totalProdutos} - Custo:{ans.custoProdutos} = LucroProdutos:{ans.lucroProduto}</h4>
                                        <h3>Serviços</h3>
                                        <h4>Qtde: {ans.qtdeServicos}   Total: {ans.totalServicos}</h4>
                                        <h3>Resultado </h3>
                                        <h4>Lucro do Dia: {ans.lucroBrutoTotal} - Despesa Diária Média:{ans.despesaOperacionalMedia}</h4>
                                        <h3>Resultado do dia: {ans.resultadoDia}</h3>

                                        {this.state.renderList}
                                        {this.state.renderList ? this.state.ourResultsList : <CompUnidade idUnidOPe={ans.idUnidade} Data={ans.dataMov} message={ans.unidade} litros={ans.totalLitros}/>} 
                                        {/* <CompUnidade idUnidOPe={ans.idUnidade} Data={ans.dataMov} message={ans.unidade} litros={ans.totalLitros}/> */}
                                        <CompUnidadeProd idUnidOPe={ans.idUnidade} Data={ans.dataMov} message={ans.unidade} aberto={this.state.checkedB}/>
                                        </CardBody>
                                        <CardFooter>
                                            {ans.totalCombustivelStr} 
                                            {ans.idUnidade}
                                        </CardFooter>      
									</Card>
                                    </GridItem>)
                        )}  


                </GridContainer>
            </div>
            )
        }

    };


}


export default ResumoUnidade;