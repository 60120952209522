import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import auth from '../../services/auth';
import '../../demo/demo.css';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import { Bar } from 'react-chartjs-2';

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import "react-datepicker/dist/react-datepicker.css";
import checatoken from '../checatoken';
import DateRange from "@material-ui/icons/DateRange";
import Table from "components/Table/Table.js";

export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);

class Estoque extends Component {

    state = {
        datalbar: [],
        ResumoProd: {
            EstoqueFisico: 0,
            TotalVendaEstoque: 0,
            TotalCompraEstoque: 0,
            Saldo: 0

        },
        TabEstoqueProd: []
    }


    async getEstoqueGrupo() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('produto/Estoque', {
            headers: { token: chave }
        }).then(res => {
            console.log(res.data)
            this.setState({
                datalbar: {
                    labels: res.data.EstoqueProduto[0].labels,
                    datasets: res.data.EstoqueProduto[1].datasets,
                    options: res.data.EstoqueProduto[2].options
                }
            })
        }
        )
    }

    async getResumoEstoque() {
        const chave = localStorage.getItem('lukos-token');
        const response = await auth.get('/produto/EstoqueRedumo', {
            headers: {
                token: chave
            }
        });
        this.setState({ ResumoProd: response.data });
    }

    async getTabEstoqueProd() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/produto/EstoqueTab', {
            headers: {token: chave}
        }).then( res => {
            this.setState({ TabEstoqueProd: res.data})
                
            }
        )
    };

    async componentDidMount() {
        await checatoken();
        await this.getEstoqueGrupo();
        await this.getResumoEstoque();
        await this.getTabEstoqueProd();
    };

    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return (<Redirect to="/login" />)
        }
        else {


            return (<div>
                <GridContainer>
                    <GridItem xs={12} sm={4} md={3} >
                        <Card>
                            <CardHeader color="info" stats icon>
                                <CardIcon color="info">
                                    <Icon>attach_money</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Qtde Produtos em Estoque</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                    {(this.state.ResumoProd.EstoqueFisico)}
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <DateRange />
                                            Quantidade total de produtos em estoque AGORA.
                                     </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3} >
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <Icon>attach_money</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Valor Venda - Estoque</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                    {numberFormat(this.state.ResumoProd.TotalVendaEstoque)}
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <DateRange />
                                            Valor do estoque pelo preço de venda.
                                    </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3} >
                        <Card>
                            <CardHeader color="danger" stats icon>
                                <CardIcon color="danger">
                                    <Icon>attach_money</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Valor Compra - Estoque</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                    {numberFormat(this.state.ResumoProd.TotalCompraEstoque)}
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <DateRange />
                                        Valor do estoque pelo preço médio de compra.
                                    </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3} >
                        <Card>
                            <CardHeader color="primary" stats icon>
                                <CardIcon color="primary">
                                    <Icon>show_chart</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Saldo</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                    {numberFormat(this.state.ResumoProd.Saldo)}
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <DateRange />
                                            Valor do estoque Venda  - Compra
                                    </CardFooter>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={6}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className="makeStyles-cardTitleWhite-87">Estoque de produtos por Grupo </h4>
                            </CardHeader>
                            <CardBody>
                                <Bar data={this.state.datalbar} height={200} options={{ legend: { display: true } }} />
                            </CardBody>
                            <CardFooter>
                                Estoque de produtos por Grupo.
                        </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Valor de Estoque por Grupo </h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Grupo", "Estoque Fisico", "Valor Venda", "Valor Compra", "Saldo"]}
                            tableData={this.state.TabEstoqueProd}
                        />
                        </CardBody>
                        <CardFooter>
                            Valor do estoque Agrupado por Grupo.
                        </CardFooter>
                    </Card>
                    </GridItem>                          

                </GridContainer>
            </div>
            )
        }

    };


}


export default Estoque;