import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import {Bar} from 'react-chartjs-2';
import auth from '../../services/auth';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import CardIcon from "../Card/CardIcon.js";
import Button from "../CustomButtons/Button.js";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import swal from 'sweetalert';
import checatoken from '../checatoken';
import Table from "components/Table/Table.js";


export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);

export const numberFormat2 = (value) =>
    new Intl.NumberFormat('pt-BR').format(value);



class Metas extends Component {

    state = {
        _metas: [],
        TabelaMeta: []
    }

    async ChecaAcesso (){
        const vAcesso = localStorage.getItem('acesso'); 
        console.log('Acesso ')
        console.log(vAcesso);
        if (vAcesso !== '1') {
            localStorage.setItem('acesso', '1'); 
            window.location.reload(false);
        }
    }    


    async componentDidMount() {
        await checatoken();
        await this.GetMetas();
        await this.getMetaItem(0);
        await this.ChecaAcesso();
    };

    async GetMetas() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/metas/metas', {
            headers: { token: chave },
        }).then(res => {
            console.log(res);
            this.setState({ _metas : res.data });
            
        })
            .catch(error => {
                console.log(error);
                swal("OPS!", "Não foi possível abrir as metas ", "error");
            })
    };

    async getDadosTabela(ID) {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/metas/tabmetas', {
            headers: {token: chave},
            params:   {idMeta: ID}
        }).then( res => {
            this.setState({ TabelaMeta: res.data})
                
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };

       async getMetaItem(ID){
            const chave = localStorage.getItem('lukos-token');
            await auth.get('/metas/getmetas', {
                headers: {token: chave},
                params:   {idMeta: ID}
            }).then( res => {
                                console.log(res.data)
                                this.setState({ datalbar :  {
                                    labels: res.data.Metas[0].labels,
                                    datasets: res.data.Metas[1].datasets,
                                    options: res.data.Metas[2].options
                                                            } 
                                                        })                            
                                this.getDadosTabela(ID);

                                }
            )        
            .catch(function (error) {
               
                swal("OPS!! Sem dados", "Não tem dados no período meta indicada", "warning");
            })  
        }              


    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return(<Redirect to="/login" />)
          }
          else
          {

        return (<div>
            <GridContainer>
                {this.state._metas.map(meta => (
                    <GridItem xs={12} sm={6} md={4} >
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <Icon>pie_chart</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>{meta.PeriodoDesc}</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                    {meta.Descricao}
                                </h3>
                                <Button color="primary" round onClick={() => this.getMetaItem(meta.idMetaVenda)}>Carregar</Button>
                            </CardHeader>
                            <CardFooter>
                                   agrupado por: {meta.VendedorDesc}
                                </CardFooter>                                
                        </Card>

                    </GridItem>


                )

                )}
                    <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="warning">
                            <h4 className="makeStyles-cardTitleWhite-87">Detalhes da Meta  </h4>
                        </CardHeader>
                        <CardBody>
                        <Bar data={this.state.datalbar} height={155} options={{
                                                                                legend: {display: false},
                                                                                scales: {
                                                                                    xAxes: [{stacked: true}],
                                                                                    yAxes: [{stacked: true}]
                                                                                }
                                                                            }}  />
                        </CardBody>
                        <CardFooter>
                              
                        </CardFooter>
                    </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Meta</h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Meta - Descrição", "Meta", "Atingido", "Falta"]}
                            tableData={this.state.TabelaMeta}
                        />
                        </CardBody>
                        <CardFooter>
                           Analise da meta.
                        </CardFooter>
                    </Card>
                    </GridItem>                                 

            </GridContainer>
        </div>
        )}

    };

    
}


export default Metas;