import React from 'react';

import { Redirect } from "react-router-dom";
import CompResumoCaixa from '../../components/DashBoard/CompResumoCaixa';


export default function ResumoCaixa() {
      const isLogged = !!localStorage.getItem('lukos-token');

      if (isLogged === false) {
        return(<Redirect to="/login" />)
      }
      else
      {  return (        
                    <div>
                      <CompResumoCaixa />
                  </div>
                  );
  };
}
