import React, { Component } from 'react';
import auth from '../services/auth';

class UnidSel extends Component {

    state = {
        data: {
            idUnidOPe: '',
            unidOpe: '',
        }
    };

    async getDadosUnidade() {
        const chave = localStorage.getItem('lukos-token');
        const response = await auth.get('/unid/dados', {
            headers: {
                token: chave
            }
        });
        this.setState({ data: response.data });
    };

    async componentDidMount() {
        await this.getDadosUnidade();
    };

    render() {
        return (<div>{this.state.data.unidOpe}</div>    
        );
    }

};

export default UnidSel;