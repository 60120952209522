import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import auth from '../../services/auth';
import '../../demo/demo.css';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import Button from "../CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import swal from 'sweetalert';


// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import "react-datepicker/dist/react-datepicker.css";
import checatoken from '../checatoken';
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";


export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);

class CompFidelidade extends Component {

    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            endDate: new Date(),
            startDate: new Date(),
            Dash: {
                pontosAcumulados: 0,
                pontosExpirados: 0,
                pontosResgatados: 0,
                pontosVigentes: 0,
                qtdeCupomResgate: 0,
                qtdeCupons: 0,
                totalAcumulado: 0,
                totalResgatado: 0,
                valorPontosAcumulados: 0,
            },
            DashTotal: {
                qtdeClientes: 0,
                pontosAbertos: 0,
                valorAberto: 0
            },
            TabelaPag: []

        };

        this.handleChangef = this.handleChangef.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state.startDate.setMonth(this.state.startDate.getMonth() - 1);
    }



    handleChange(date) {
        this.setState({ startDate: date })

    }

    handleChangef(date) {
        this.setState({ endDate: date })

    }


    handleSubmit(e) {
        e.preventDefault();
        this.getCompCombLitros();
        this.getDadosTabela();
    }

    async getCompCombLitros() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/fidelidade/dashFidel', {
            headers: { token: chave },
            params: { dataIni: this.state.startDate, dataFim: this.state.endDate }
        }).then(res => {
            console.log(res.data)
            this.setState({ Dash: res.data });
        }
        )
    }

    async getFidelTotal() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/fidelidade/dashFidelTotal', {
            headers: { token: chave },
            params: { dataIni: this.state.startDate, dataFim: this.state.endDate }
        }).then(res => {
            console.log(res.data)
            this.setState({ DashTotal: res.data });
        }
        )
    }

    async getDadosTabela() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/fidelidade/fidelicketmedio', {
            headers: { token: chave },
            params: { dataIni: this.state.startDate, dataFim: this.state.endDate }
        }).then(res => {
            this.setState({ TabelaPag: res.data })
            console.log(res);
            console.log(this.state.TabelaPag);

        }
        )
            .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro" + error, "warning");
            })
    };

    async componentDidMount() {
        await checatoken();
        await this.getFidelTotal();
        await this.getCompCombLitros();
        await this.getDadosTabela();
    };

    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return (<Redirect to="/login" />)
        }
        else {


            return (<div>
                <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <Icon>add_shopping_cart</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Clientes Participantes</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                    {this.state.DashTotal.qtdeClientes}
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <DateRange />
                                Total de clientes ativos no programa fidelidade
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <Card>
                            <CardHeader color="info" stats icon>
                                <CardIcon color="info">
                                    <Icon>attach_money</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Valor em Aberto</h6>
                                <h3 className='makeStyles-cardTitle-91'>{numberFormat(this.state.DashTotal.valorAberto)}  </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <DateRange />
                                Valor total aguardando resgate.
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <Card>
                            <CardHeader color="primary" stats icon>
                                <CardIcon color="primary">
                                    <Icon>show_chart</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Pontos Ativos</h6>
                                <h3 className='makeStyles-cardTitle-91'>{this.state.DashTotal.pontosAbertos} </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <LocalOffer />
                                Total de pontos ativos.
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} >
                        <Card>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning">
                                    <Icon>sentiment_satisfied</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Selecione o período</h6>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <div>
                                            <label>Data Inicial: </label>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    //   timeFormat={false}
                                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                                    timeFormat={false}
                                                    name="startDate"
                                                    id="startDate"
                                                    locale="pt-br"
                                                    dateFormat="DD/MM/yyyy"
                                                    closeOnSelect="true"
                                                    input="false"
                                                    defaultValue={this.state.startDate}
                                                    onChange={this.handleChange}
                                                    inputProps={{ placeholder: "Date Picker Here" }}
                                                />
                                            </FormControl>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <div>
                                            <label>Data Final : </label>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    //   timeFormat={false}
                                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                                    timeFormat={false}
                                                    name="endDate"
                                                    id="endDate"
                                                    locale="pt-br"
                                                    dateFormat="DD/MM/yyyy"
                                                    closeOnSelect="true"
                                                    defaultValue={this.state.endDate}
                                                    onChange={this.handleChangef}
                                                    inputProps={{ placeholder: "Date Picker Here" }}
                                                />
                                            </FormControl>
                                            {/* <DatePicker
                                name="endDate"
                                id="endDate"
                                selected={ this.state.endDate }
                                onChange={ this.handleChangef}
                                /> */}
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={2} md={2}>
                                        <br></br>
                                        <Button color="primary" round onClick={this.handleSubmit}>

                                            Filtrar

                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>

                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <Card>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning">
                                    <Icon>add_shopping_cart</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Qtde Cupons periodo</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                    {this.state.Dash.qtdeCupons}
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <DateRange />
                                cupons com Fidelidade
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <Icon>attach_money</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Total Consumido</h6>
                                <h3 className='makeStyles-cardTitle-91'>{numberFormat(this.state.Dash.totalAcumulado)}  </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <DateRange />
                                Valor total que gerou cashback
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <Card>
                            <CardHeader color="primary" stats icon>
                                <CardIcon color="primary">
                                    <Icon>show_chart</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Pontos Acumulados</h6>
                                <h3 className='makeStyles-cardTitle-91'>{this.state.Dash.pontosAcumulados} </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <LocalOffer />
                                Total de pontos do periodo
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <Card>
                            <CardHeader color="rose" stats icon>
                                <CardIcon color="rose">
                                    <Icon>attach_money</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>CashBack Gerado</h6>
                                <h3 className='makeStyles-cardTitle-91'>{numberFormat(this.state.Dash.valorPontosAcumulados)}  </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <DateRange />
                                Valor total disponivel para resgate
                            </CardFooter>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={3} md={3}>
                        <Card>
                            <CardHeader color="rose" stats icon>
                                <CardIcon color="rose">
                                    <Icon>add_shopping_cart</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Qtde Cupons Resgate</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                    {this.state.Dash.qtdeCupomResgate}
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <DateRange />
                                Qtde cupons com Resgate
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <Card>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning">
                                    <Icon>attach_money</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Total Resgatado</h6>
                                <h3 className='makeStyles-cardTitle-91'>{numberFormat(this.state.Dash.totalResgatado)}  </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <DateRange />
                                CashBack acumulado
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <Card>
                            <CardHeader color="danger" stats icon>
                                <CardIcon color="danger">
                                    <Icon>show_chart</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Pontos resgatados</h6>
                                <h3 className='makeStyles-cardTitle-91'>{this.state.Dash.pontosResgatados} </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <LocalOffer />
                                Total de pontos do periodo
                            </CardFooter>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={3} md={3}>
                        <Card>
                            <CardHeader color="info" stats icon>
                                <CardIcon color="info">
                                    <Icon>show_chart</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>Pontos expirados</h6>
                                <h3 className='makeStyles-cardTitle-91'>{this.state.Dash.pontosExpirados} </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <LocalOffer />
                                Total de pontos expirados no periodo
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning">
                                <h4 className="makeStyles-cardTitleWhite-87">Comparativo Ticket Médio Fidelidade</h4>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableHeaderColor="primary"
                                    tableHead={["Tipo", "Abastecimentos", "Cupons", "Litros", "Total Vendido", "Média Cupom", "Média Abast.", "Média Litros", "Porcentagem"]}
                                    tableData={this.state.TabelaPag}
                                />
                            </CardBody>
                            <CardFooter>
                                Funcionários com a maior quantidade de vendas
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>

            </div>
            )
        }

    };


}


export default CompFidelidade;