import React, { Component } from 'react';
import auth from '../../services/auth';
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import {Line} from 'react-chartjs-2';


class ValorHoraChart extends Component {


    state = {
        dataline : {},
    }

    async componentDidMount() {
        const vAcesso = localStorage.getItem('acesso'); 
        if (vAcesso === '1'){
            await this.getgrafcombpordia();
        }
    };    


    async getgrafcombpordia(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/unid/getvalorhora', {
            headers: {token: chave}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ dataline :  {
                                labels: res.data.ValorHora[0].labels,
                                datasets: res.data.ValorHora[1].datasets,
                                options: res.data.ValorHora[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }    

    render() {
        return (<div>
                <Card>
                        <CardHeader color="info">
                            <h4 className="makeStyles-cardTitleWhite-87">Vendas das ultimas 24 hs em R$ </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                            <Line data={this.state.dataline} height={200}   options={{legend: { display: false}}}/>
                    </div>
                        </CardBody>
                        <CardFooter>
                               Valores de cupons emitidos. Valor de referência por hora de emissão do cupom.
                        </CardFooter>
                    </Card>            
        </div>
        )

    }
}

export default ValorHoraChart;