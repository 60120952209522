import React, { Component } from 'react';
import auth from '../../services/auth';
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import {Line} from 'react-chartjs-2';

class QtdeHoraChart extends Component {


    state = {
        datalineqtde : {},
    }

    async componentDidMount() {
        const vAcesso = localStorage.getItem('acesso'); 
        if (vAcesso === '1'){
            await this.getgrafcombpordia();
        }
    };    


    async getgrafcombpordia(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/unid/getqtdehora', {
            headers: {token: chave}
        }).then( res => {
                            console.log(res.data);
                            this.setState({ datalineqtde :  {
                                labels: res.data.QtdeHora[0].labels,
                                datasets: res.data.QtdeHora[1].datasets,
                                options: res.data.QtdeHora[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }    

    render() {
        return (<div>
                <Card>
                        <CardHeader color="warning">
                            <h4 className="makeStyles-cardTitleWhite-87">Venda das ultimas 24hs - Qtde Cupons </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                            <Line data={this.state.datalineqtde} height={200}   options={{legend: { display: false}}}/>
                    </div>
                        </CardBody>
                        <CardFooter>
                                Quantidade de cupons emitidos. Valor de referência por hora de emissão do cupom.
                        </CardFooter>
                    </Card>            
        </div>
        )

    }
}

export default QtdeHoraChart;