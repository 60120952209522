import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import auth from '../../services/auth';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardFooter from "../Card/CardFooter.js";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import swal from 'sweetalert';
import checatoken from '../checatoken';
import CardBody from "../Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Button from "../CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'react-select'



export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);


class CompResumoCaixa extends Component {

    constructor (props) {
        super(props)
        this.state = {startDate: new Date(),
                    };
            
              this.handleChange = this.handleChange.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);

             this.state = {endDate: new Date(),
                 startDate: new Date(),
                 _Caixas: [],
                 checkedB: true,
                 checkedA: true,
                 checkedT: true,
                 selectedOption: null,
                 ComboUnid: [],
                    };
            
              this.handleChangef = this.handleChangef.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);

              this.state.startDate.setHours(this.state.startDate.getDay() - 168);
    }

      
    handleChangeUN = selectedOption => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
      };
    
    handleChange(date) {
            this.setState({ startDate: date })
  
    }
    
    handleChangef(date) {
        this.setState({ endDate: date })

    }


    handleChangeC = event =>
        this.setState({checkedB: event.target.checked});    

     handleChangeCA = event =>
        this.setState({checkedA: event.target.checked});

     handleChangeT = event =>
        this.setState({checkedT: event.target.checked});

    handleSubmit(e) {
        e.preventDefault();
        this.getComboUnid();

        this.GetTanque();
    }    

    async componentDidMount() {
            await checatoken();
            await this.getComboUnid();
            await this.GetTanque();
    };

    async getComboUnid() {
        const chave = localStorage.getItem('lukos-token');
        const response = await auth.get('/users/ComboUnidades', {
            headers: {
                token: chave
            }
        });
        this.setState({ ComboUnid: response.data });
    }

    async getDadosUnidade() {
        const chave = localStorage.getItem('lukos-token');
        const response = await auth.get('/unid/dados', {
            headers: {
                token: chave
            }
        });
        this.setState({ unid: response.data });
    }
    
    async GetTanque() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/resumocaixa/consulta', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate, Unid: this.state.selectedOption, Aberto: this.state.checkedA, Encerrado: this.state.checkedB, Trancado: this.state.checkedT}
        }).then( res => {
            console.log(res.data)
            this.setState({ _Caixas: res.data})
                
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };    

  

    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return(<Redirect to="/login" />)
          }
          else
          {
        
        return (<div>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12} >
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>sentiment_satisfied</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Selecione o período</h6>
                        </CardHeader>
                        <CardBody>
                        <GridContainer> 
                        <GridItem xs={12} sm={4} md={4}>
                        <div>
                            <label>Data Inicial: </label>
                                <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="startDate"
                                    id="startDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    input="false"
                                    defaultValue = {this.state.startDate}
                                    onChange={ this.handleChange }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>                                
                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                         <div>
                            <label>Data Final : </label>
                            <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="endDate"
                                    id="endDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    defaultValue = {this.state.endDate}
                                    onChange={ this.handleChangef }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>    
                                {/* <DatePicker
                                name="endDate"
                                id="endDate"
                                selected={ this.state.endDate }
                                onChange={ this.handleChangef}
                                /> */}
                        </div> 
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            Unidade Operacional {this.selectedOption}
                            <Select 
                                options={this.state.ComboUnid} 
                                onChange={this.handleChangeUN}
                                value={this.selectedOption}
                            />
                        </GridItem>




                        <GridItem xs={12} sm={12} md={12}>
                        <FormControlLabel
                                control={
                                <Checkbox
                                    checked={this.state.checkedA}
                                    onChange={this.handleChangeCA}
                                    name="checkedA"
                                    color="primary"
                                />
                                }
                                label="Abertos"
                            /> 
                        <FormControlLabel
                                control={
                                <Checkbox
                                    checked={this.state.checkedB}
                                    onChange={this.handleChangeC}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label="Encerrados"
                            />  
                        <FormControlLabel
                                control={
                                <Checkbox
                                    checked={this.state.checkedT}
                                    onChange={this.handleChangeT}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label="Trancados"
                            />                             
                          </GridItem>                        
                        <GridItem xs={12} sm={12} md={12}>
                            <br></br>
                        <Button color="primary" round onClick={ this.handleSubmit}>

                                Filtrar
                                
                            </Button>
                        </GridItem>
                        </GridContainer>
                        </CardBody>
                        <CardFooter>

                         </CardFooter>
                    </Card>
                    </GridItem>
                    

                {this.state._Caixas.map(Caixa => (
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color={Caixa.CorCombustivel} stats icon>
                            <CardIcon color="info">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <h6>{Caixa.UnidadeOperacional} - {Caixa.idCaixaAbertura}</h6>
                            <br></br>
                            <h3>{Caixa.StatusCaixa}</h3>
                            <h3>{numberFormat(Caixa.TotalSaidas)}</h3>
                            <h6>Diferença de Caixa {numberFormat(Caixa.Diferenca)}</h6>
                            <h6>Turno {Caixa.Turno} - Box {Caixa.Box}</h6>
                            <h6>Operador {Caixa.Operador}</h6>
                            <h6>Data de Inicio: {Caixa.DataHora}</h6>
                            <h6>Data de Término: {Caixa.DataEncerramento}</h6>
                            <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Card>
                                    <CardHeader color="success" stats icon>
                                    <CardIcon color="success">
                                        <Icon>attach_money</Icon>
                                    </CardIcon>
                                        <br></br>
                                        <h3>Entradas</h3>
                                    </CardHeader>
                                    <h6>Troco Abertura   {numberFormat(Caixa.TrocoAbertura)}</h6>
                                    <h6>Produtos   {numberFormat(Caixa.TotalProdutos)}</h6>
                                    <h6>Combustivel   {numberFormat(Caixa.TotalCombustivel)}</h6>
                                    <h6>Servicos   {numberFormat(Caixa.TotalServicos)}</h6>
                                    <h6>Recebimentos   {numberFormat(Caixa.TotalRecebimentos)}</h6>
                                    <h6>Suprimentos   {numberFormat(Caixa.TotalSuprimentos)}</h6>
                                    <h6>Descontos / Acrescimos  {numberFormat(Caixa.DescontoCupom)}</h6>
                                    <CardFooter stats>
                                        <h6>Total de Entradas</h6> {numberFormat(Caixa.TotalEntradas)}
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Card>
                                    <CardHeader color="success" stats icon>
                                    <CardIcon color="danger">
                                        <Icon>attach_money</Icon>
                                    </CardIcon>
                                        <br></br>
                                        <h3>Saídas</h3>
                                    </CardHeader>
                                    <h6>Troco Fechamento   {numberFormat(Caixa.TrocoEncerramento)}</h6>
                                    <h6>Pagamentos Despesas   {numberFormat(Caixa.TotalPagamentos)}</h6>
                                    <h6>Sangrias   {numberFormat(Caixa.TotalSangrias)}</h6>
                                    <CardFooter stats>
                                            <h6>Total de Saídas</h6> {numberFormat(Caixa.TotalSaidas)}
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            </GridContainer>

                            
                        </CardHeader>
                        <CardFooter >
                    </CardFooter>
                    <Button color="info" round onClick={event =>  window.location.href=`/admin/detalhecaixa/${Caixa.idCaixaAbertura}`}>
                    Detalhes
                    </Button>                    
                    </Card>

                </GridItem>)
                 )}
            </GridContainer>
        </div>
        )}

    };

    
}


export default CompResumoCaixa;