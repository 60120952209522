import React, { Component } from 'react';
import auth from '../../services/auth';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardFooter from "../Card/CardFooter.js";
import CardBody from "../Card/CardBody.js";

// @material-ui/icons

import swal from 'sweetalert';
import Table from "components/Table/Table.js";

export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);

export const numberFormat2 = (value) =>
    new Intl.NumberFormat('pt-BR').format(value);


type Texto = {
    // using `interface` is also ok
    idUnidOPe: String;
    Data: String;
    message: string;
    litros:String;
    };    
    
class CompUnidade extends Component<Texto> {



    state = {
        data: {
            idUnidOPe: '',
            unidOpe: '',
        },
        TabComb : [],
    }

    async getDadosTabela() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/metas/UnidadeDataTabComb', {
            headers: {token: chave},
            params:   {dataIni : this.props.Data, idUnid: this.props.idUnidOPe}
            
        }).then( res => {
            console.log(res);
            this.setState({ TabComb: res.data})
                
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };   

    async componentDidMount() {
      //  await checatoken();
        await this.getDadosTabela();
        };


    render() {
        return (<div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Venda de Combustivel <br/> Total de Litros:{this.props.litros}</h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Combustível", "Litros", "Unit. Médio", "Total Venda", "Lucro Bruto", "Margem %"]}
                            tableData={this.state.TabComb}
                        />
                        </CardBody>
                        <CardFooter>
                            Vendas de combustível  do dia.
                        </CardFooter>
                    </Card>
                </GridItem>             
                {/* <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                                {this.props.message}
                         </CardHeader>
                        <CardFooter stats>
                            <DateRange />
                            " idUnidade": {this.props.idUnidOPe}  
                            "Data: " {this.props.Data}
                            "Mensagem: "{this.props.message}
                        </CardFooter>
                    </Card>
                </GridItem> */}
            </GridContainer>
        </div>
        )
        }
    };



export default CompUnidade;