import React from 'react';

import { Redirect } from "react-router-dom";
import CompFidelidade from '../../components/DashBoard/compcfidelidade';


export default function Fidelidade() {
      const isLogged = !!localStorage.getItem('lukos-token');

      if (isLogged === false) {
        return(<Redirect to="/login" />)
      }
      else
      {  return (        
                    <div>
                      <CompFidelidade />
                  </div>
                  );
  };
}
