import React, { Component } from 'react';
import auth from '../../services/auth';
import '../../demo/demo.css';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import { Bar } from 'react-chartjs-2';

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import "react-datepicker/dist/react-datepicker.css";
import checatoken from '../checatoken';
import DateRange from "@material-ui/icons/DateRange";
import Table from "components/Table/Table.js";

export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);

export const numberFormat2 = (value) =>
   new Intl.NumberFormat('pt-BR').format(value);

class EstoqueTanque extends Component {

    state = {
        datalbar: [],
        ResumoTanques: { EstoqueAtual : 0,
                      TotalPrecoVenda: 0,
                      TotalCompra: 0,
                      Saldo: 0
                        
        },
        TabTanques: []
    }    


    async getEstoqueTanque(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('produto/Tanques', {
            headers: {token: chave}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ datalbar :  {
                                labels: res.data.EstoqueTanque[0].labels,
                                datasets: res.data.EstoqueTanque[1].datasets,
                                options: res.data.EstoqueTanque[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }     

    async getResumoTanque() {
        const chave = localStorage.getItem('lukos-token');
        const response = await auth.get('/produto/TanquesResumo', {
            headers: {
                token: chave
            }
        });
        this.setState({ ResumoTanques: response.data });
    }

    async getTabTanques() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/produto/TanquesTab', {
            headers: {token: chave}
        }).then( res => {
            this.setState({ TabTanques: res.data})
            }
        )
    };

    async componentDidMount() {
        await checatoken();
        await this.getEstoqueTanque();
        await this.getResumoTanque();
        await this.getTabTanques();
    };

    render() {
        if (this.state.ResumoTanques.EstoqueAtual === 0) {
            return ('')
        }
        else {


            return (<div>
                <GridContainer>
                            <GridItem xs={12} sm={4} md={3} >
                                <Card>
                                    <CardHeader color="info" stats icon>
                                        <CardIcon color="info">
                                            <Icon>attach_money</Icon>
                                        </CardIcon>
                                        <br></br>
                                        <h6>Litros nos Tanques</h6>
                                        <h3 className='makeStyles-cardTitle-91'>
                                            {numberFormat2(this.state.ResumoTanques.EstoqueAtual)}
                                        </h3>
                                    </CardHeader>
                                    <CardFooter stats>
                                        <DateRange />
                                            Quantidade total  em todos tanques.
                                     </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={3} >
                                <Card>
                                    <CardHeader color="success" stats icon>
                                        <CardIcon color="success">
                                            <Icon>attach_money</Icon>
                                        </CardIcon>
                                        <br></br>
                                        <h6>Valor Venda - Tanques</h6>
                                        <h3 className='makeStyles-cardTitle-91'>
                                            {numberFormat(this.state.ResumoTanques.TotalPrecoVenda)}
                                        </h3>
                                    </CardHeader>
                                    <CardFooter stats>
                                        <DateRange />
                                            Valor dos tanques pelo preço de venda.
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={3} >
                                <Card>
                                    <CardHeader color="danger" stats icon>
                                        <CardIcon color="danger">
                                            <Icon>attach_money</Icon>
                                        </CardIcon>
                                        <br></br>
                                        <h6>Valor Compra - Tanques</h6>
                                        <h3 className='makeStyles-cardTitle-91'>
                                            {numberFormat(this.state.ResumoTanques.TotalCompra)}
                                        </h3>
                                    </CardHeader>
                                    <CardFooter stats>
                                        <DateRange />
                                        Valor dos tanques pelo preço médio de compra.
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={3} >
                                <Card>
                                    <CardHeader color="primary" stats icon>
                                        <CardIcon color="primary">
                                            <Icon>show_chart</Icon>
                                        </CardIcon>
                                        <br></br>
                                        <h6>Saldo</h6>
                                        <h3 className='makeStyles-cardTitle-91'>
                                        {numberFormat(this.state.ResumoTanques.Saldo)}
                                        </h3>
                                    </CardHeader>
                                    <CardFooter stats>
                                        <DateRange />
                                            Valor de Saldo dos tanques = (Venda - Compra) 
                                    </CardFooter>
                                </Card>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Estoque de Combustivel</h4>
                        </CardHeader>
                        <CardBody>
                        <Bar data={this.state.datalbar} height={100}  options={{legend: { display: true}}} />
                        </CardBody>
                        <CardFooter>
                               Estoque dos tanques Agrupado por Combustivel.
                        </CardFooter>
                    </Card>
                </GridItem>       
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Valor dos tanques por Combustivel</h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Combustivel", "Estoque Fisico", "Valor Venda", "Valor Compra", "Saldo"]}
                            tableData={this.state.TabTanques}                            
                        />
                        </CardBody>
                        <CardFooter>
                            Valor dos Tanques Agrupado por Combustivel.
                        </CardFooter>
                    </Card>
                    </GridItem>                         

                </GridContainer>
            </div>
            )
        }

    };


}


export default EstoqueTanque;