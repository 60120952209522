import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import auth from '../../services/auth';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardFooter from "../Card/CardFooter.js";
import Danger from "../Typography/Danger.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import swal from 'sweetalert';
import checatoken from '../checatoken';



class posicaotanque extends Component {

    state = {
        _Tanques: [],
        data: {

        },
        unid: {
            idUnidOPe: '',
            unidOpe: '',
        },
    }

    async componentDidMount() {
            await checatoken();
            await this.GetTanque();
    };

    async getDadosUnidade() {
        const chave = localStorage.getItem('lukos-token');
        const response = await auth.get('/unid/dados', {
            headers: {
                token: chave
            }
        });
        this.setState({ unid: response.data });
    }

    async GetTanque() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/unid/gettanques', {
            headers: { token: chave },
        }).then(res => {
            console.log(res);
            this.setState({ _Tanques: res.data });
        })
            .catch(error => {
                console.log(error);
                swal("OPS!", "Não foi possível pegar os dados dos tanques. ", "error");
            })
    }    

    

    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return(<Redirect to="/login" />)
          }
          else
          {
        
        return (<div>
            <GridContainer>
                {this.state._Tanques.map(Tanque => (
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color={Tanque.CorCombustivel} stats icon>
                            <CardIcon color={Tanque.CorCombustivel} >
                                <Icon>local_gas_station_sharp</Icon>
                            </CardIcon>
                            <h6>{Tanque.UnidadeOperacional} - {Tanque.TanqueCombustivel}</h6>
                            <br></br>
                            <h3>{Tanque.Produto}</h3>
                            <h3 cl1assName='makeStyles-cardTitle-91'>
                                 {Tanque.EstoqueAtual} lts
                            </h3>
                            <h6>Operando com {Tanque.porcentagemTanque} % da capacidade </h6>
                        </CardHeader>
                        <CardFooter >
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <CustomLinearProgress
                                variant="determinate"
                                color={Tanque.Corprogresso}
                                value={Tanque.porcentagemTanque}
                            />
{/* --                            <Line percent={Tanque.porcentagemTanque} strokeWidth="4" strokeColor={Tanque.Corprogresso} /> */}
                       </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Danger> 
                                <div className="makeStyles-defaultFontStyle-319">
                                    {Tanque.TerminoAproximado}
                                </div>
                            </Danger> 
                            
                        </GridItem>
                        </GridContainer>
                        </CardFooter>
                    </Card>
                </GridItem>)
                 )}
            </GridContainer>
        </div>
        )}

    };

    
}


export default posicaotanque;