import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import auth from '../../services/auth';
import '../../demo/demo.css';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import Button from "../CustomButtons/Button.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import Table from "components/Table/Table.js";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import swal from 'sweetalert';
import checatoken from '../checatoken';


export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);

export const numberFormat2 = (value) =>
    new Intl.NumberFormat('pt-BR').format(value);



class TrocaPreco extends Component {

    state = {
        _Combs: [],
        data: {

        },
        unid: {
            idUnidOPe: '',
            unidOpe: '',
        },
        TabPrecoConc: []
    }

    dataChange(ev) {
        let name = [ev.target.name];
        let value = ev.target.value;
        this.setState(prevState => ({
            data: { ...prevState.data, [name]: value }
        }));
    }


    fnTrocaPreco(vcomb) {
        swal("Trocar o Preço", "Deseja realmente mudar o preço do combustivel?", {
            buttons: {
                cancel: "Cancelar",
                catch: {
                    text: "Trocar agora",
                    value: "catch",
                },
            },
        })
            .then((value) => {
                const chave = localStorage.getItem('lukos-token');
                switch (value) {
                    case "catch":
                        if (vcomb === 'Comb1') {
                            auth.post('/unid/postcombustivel', {
                                headers: {
                                    token: chave
                                },
                                params: {
                                    Combustivel: '1',
                                    Preco: this.state.data.Comb1,
                                    token: chave
                                }
                            }

                            ).then(res => {
                                swal("Trocado!", "Em até 5 minutos muda nas bombas. Certifique-se que tenha internet no computador do PDV.", "success")
                                .then((value) => {
                                    window.location.reload(false);
                                });
                            })

                                .catch(error => {
                                    swal("Não foi possível trocar o preço!", "Verifique o valor digitado e tente novamente.", "warning");
                                })
                        } //fim If

                        if (vcomb === 'Comb2') {
                            auth.post('/unid/postcombustivel', {
                                headers: {
                                    token: chave
                                },
                                params: {
                                    Combustivel: '2',
                                    Preco: this.state.data.Comb2,
                                    token: chave
                                }
                            }

                            ).then(res => {
                                swal("Trocado!", "Em até 5 minutos muda nas bombas. Certifique-se que tenha internet no computador do PDV.", "success")
                                .then((value) => {
                                    window.location.reload(false);
                                });
                            })

                                .catch(error => {
                                    swal("Não foi possível trocar o preço!", "Verifique o valor digitado e tente novamente.", "warning");
                                })
                        } //fim If                

                        if (vcomb === 'Comb3') {
                            auth.post('/unid/postcombustivel', {
                                    headers: {
                                        token: chave
                                    },
                                params: {
                                    Combustivel: '3',
                                    Preco: this.state.data.Comb3,
                                    token: chave
                                }
                            }

                            ).then(res => {
                               swal("Trocado!", "Em até 5 minutos muda nas bombas. Certifique-se que tenha internet no computador do PDV.", "success")
                                .then((value) => {
                                    window.location.reload(false);
                                });
                            })

                                .catch(error => {
                                    swal("Não foi possível trocar o preço!", "Verifique o valor digitado e tente novamente.", "warning");
                                })
                        } //fim If                


                        if (vcomb === 'Comb4') {
                            auth.post('/unid/postcombustivel', {
                                    headers: {
                                        token: chave
                                    },
                                params: {
                                    Combustivel: '4',
                                    Preco: this.state.data.Comb4,
                                    token: chave
                                }
                            }

                            ).then(res => {
                                swal("Trocado!", "Em até 5 minutos muda nas bombas. Certifique-se que tenha internet no computador do PDV.", "success")
                                .then((value) => {
                                    window.location.reload(false);
                                });
                            })

                                .catch(error => {
                                    swal("Não foi possível trocar o preço!", "Verifique o valor digitado e tente novamente.", "warning");
                                })
                        } //fim If                


                        if (vcomb === 'Comb5') {
                            auth.post('/unid/postcombustivel', {
                                params: {
                                    Combustivel: '5',
                                    Preco: this.state.data.Comb5,
                                    token: chave
                                }
                            }

                            ).then(res => {
                                swal("Trocado!", "Em até 5 minutos muda nas bombas. Certifique-se que tenha internet no computador do PDV.", "success")
                                .then((value) => {
                                    window.location.reload(false);
                                });
                            })

                                .catch(error => {
                                    swal("Não foi possível trocar o preço!", "Verifique o valor digitado e tente novamente.", "warning");
                                })
                        } //fim If                


                        if (vcomb === 'Comb6') {
                            auth.post('/unid/postcombustivel', {
                                params: {
                                    Combustivel: '6',
                                    Preco: this.state.data.Comb6,
                                    token: chave
                                }
                            }

                            ).then(res => {
                                swal("Trocado!", "Em até 5 minutos muda nas bombas. Certifique-se que tenha internet no computador do PDV.", "success")
                                .then((value) => {
                                    window.location.reload(false);
                                });
                            })

                                .catch(error => {
                                    swal("Não foi possível trocar o preço!", "Verifique o valor digitado e tente novamente.", "warning");
                                })
                        } //fim If                


                        if (vcomb === 'Comb7') {
                            auth.post('/unid/postcombustivel', {
                                params: {
                                    Combustivel: '7',
                                    Preco: this.state.data.Comb7,
                                    token: chave
                                }
                            }

                            ).then(res => {
                                swal("Trocado!", "Em até 5 minutos muda nas bombas. Certifique-se que tenha internet no computador do PDV.", "success")
                                .then((value) => {
                                    window.location.reload(false);
                                });
                            })

                                .catch(error => {
                                    swal("Não foi possível trocar o preço!", "Verifique o valor digitado e tente novamente.", "warning");
                                })
                        } //fim If                


                        if (vcomb === 'Comb8') {
                            auth.post('/unid/postcombustivel', {
                                params: {
                                    Combustivel: '8',
                                    Preco: this.state.data.Comb8,
                                    token: chave
                                }
                            }

                            ).then(res => {
                                swal("Trocado!", "Em até 5 minutos muda nas bombas. Certifique-se que tenha internet no computador do PDV.", "success")
                                .then((value) => {
                                    window.location.reload(false);
                                });
                            })

                                .catch(error => {
                                    swal("Não foi possível trocar o preço!", "Verifique o valor digitado e tente novamente.", "warning");
                                })
                        } //fim If                


                        if (vcomb === 'Comb9') {
                            auth.post('/unid/postcombustivel', {
                                params: {
                                    Combustivel: '9',
                                    Preco: this.state.data.Comb9,
                                    token: chave
                                }
                            }

                            ).then(res => {
                                swal("Trocado!", "Em até 5 minutos muda nas bombas. Certifique-se que tenha internet no computador do PDV.", "success")
                                .then((value) => {
                                    window.location.reload(false);
                                });
                            })

                                .catch(error => {
                                    swal("Não foi possível trocar o preço!", "Verifique o valor digitado e tente novamente.", "warning");
                                })
                        } //fim If                


                        if (vcomb === 'Comb10') {
                            auth.post('/unid/postcombustivel', {
                                params: {
                                    Combustivel: '10',
                                    Preco: this.state.data.Comb10,
                                    token: chave
                                }
                            }

                            ).then(res => {
                                swal("Trocado!", "Em até 5 minutos muda nas bombas. Certifique-se que tenha internet no computador do PDV.", "success")
                                .then((value) => {
                                    window.location.reload(false);
                                });
                            })

                                .catch(error => {
                                    swal("Não foi possível trocar o preço!", "Verifique o valor digitado e tente novamente.", "warning");
                                })
                        } //fim If                


                        if (vcomb === 'Comb11') {
                            auth.post('/unid/postcombustivel', {
                                params: {
                                    Combustivel: '11',
                                    Preco: this.state.data.Comb11,
                                    token: chave
                                }
                            }

                            ).then(res => {
                                this.GetCombustivel();
                                swal("Trocado!", "Em até 5 minutos muda nas bombas. Certifique-se que tenha internet no computador do PDV.", "success")
                                .then((value) => {
                                    window.location.reload(false);
                                });
                            })

                                .catch(error => {
                                    swal("Não foi possível trocar o preço!", "Verifique o valor digitado e tente novamente.", "warning");
                                })
                        } //fim If                


                        break;
                        default:
                }
            });

        // if (vcomb == 'Comb1') {
        //                 auth.post('/unid/postcombustivel', {params: {
        //                     Combustivel : '1',
        //                     Preco : this.state.data.Comb1
        //                 } 
        //         }

        //     ).then(res => {
        //             console.log(res);
        //             })
        //         .catch(error => {
        //         console.log(error);
        //         alert('Usuario ou Senha Inválidos');
        //     })

        //     swal("MudaPReco2!", "idcombustivel = 1 para"+this.state.data.Comb1, "success");        
        // } else  if (vcomb == 'Comb2') {
        //     swal("MudaPReco!", "idcombustivel = 2 para"+this.state.data.Comb2, "success");        
        // } else  if (vcomb == 'Comb3') {
        //     swal("MudaPReco!", "idcombustivel = 3 para"+this.state.data.Comb3, "success");        
        // } else  if (vcomb == 'Comb4') {
        //     swal("MudaPReco!", "idcombustivel = 4 para"+this.state.data.Comb4, "success");        
        // } else  if (vcomb == 'Comb5') {
        //     swal("MudaPReco!", "idcombustivel = 5 para"+this.state.data.Comb5, "success");        
        // } else  if (vcomb == 'Comb6') {
        //     swal("MudaPReco!", "idcombustivel = 6 para"+this.state.data.Comb6, "success");        
        // } else  if (vcomb == 'Comb7') {
        //     swal("MudaPReco!", "idcombustivel = 7 para"+this.state.data.Comb7, "success");        
        // } else {
        //       swal("MudaPReco!", "Sem comb", "success");        

        //  }

    }




    handleSubmit = event => {
        event.preventDefault();
        // let ObjCombustivel = {
        //     Comb1: this.state.data.Comb1,
        //     Comb2: this.state.data.Comb2,
        //     Comb3: this.state.data.Comb3,
        //     Comb4: this.state.data.Comb4,
        //     Comb5: this.state.data.Comb5,
        //     Comb6: this.state.data.Comb6,
        //     Comb7: this.state.data.Comb7,
        //     Comb8: this.state.data.Comb8,
        //     Comb9: this.state.data.Comb9,
        //     Comb10: this.state.data.Comb10,
        //     Comb11: this.state.data.Comb11,
        // };

    };


    async componentDidMount() {
        await checatoken();
        await this.getDadosUnidade();
        await this.GetCombustivel();
        await this.getTabPrecoConc();
    };

    async getDadosUnidade() {
        const chave = localStorage.getItem('lukos-token');
        const response = await auth.get('/unid/dados', {
            headers: {
                token: chave
            }
        });
        this.setState({ unid: response.data });
    }

    async getTabPrecoConc() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/produto/PrecoConciTab', {
            headers: {token: chave}
        }).then( res => {
            this.setState({ TabPrecoConc: res.data})
                
            }
        )
    };

    async GetCombustivel() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/unid/getcombustivel', {
            headers: { token: chave },
        }).then(res => {
            this.setState({ _Combs: res.data });
        })
            .catch(error => {
                swal("OPS!", "Não foi possível abrir a pagina. Selecione uma Unidade Operacional ", "error");
            })
    }



    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return(<Redirect to="/login" />)
          }
          else
          {

        return (<div>
            <h3> {this.state.unid.unidOpe}</h3>
            <GridContainer>
                {this.state._Combs.map(Comb => (
                    <GridItem xs={12} sm={6} md={4} key={Comb.Numero}>
                        <Card>
                            <CardHeader color={Comb.cor} stats icon>
                                <CardIcon color={Comb.cor}>
                                    <Icon>attach_money</Icon>
                                </CardIcon>
                                <br></br>
                                <h6>{Comb.Operacional}</h6>
                                <h3 className='makeStyles-cardTitle-91'>
                                    R$ {Comb.PrecoVenda}
                                </h3>
                                <input   type='text' placeholder="Novo valor" min="0.001" max="9.999"  name={Comb.Numero} id={Comb.Numero} onChange={this.dataChange.bind(this)} className="form-control" />
                                <Button color="primary"  round onClick={() => this.fnTrocaPreco(Comb.Numero)}>Trocar o Preco</Button>
                                
                            </CardHeader>
                            
                        </Card>
                        
                    </GridItem>
                )

                )}
                <Card>
                    <CardHeader color="success">
                        <h4>Ganho por Forma de Pagamento</h4>
                    </CardHeader>
                    <CardBody>
                    <Table
                            tableHeaderColor="primary"
                            tableHead={["Combustivel", "Preço Venda", "TanqueCombustivel", "Valor Compra", "Tipo Conciliador", "bandeira","Modalidade", "Taxa %", "Taxa R$", "Ganho por Litro"]}
                            tableData={this.state.TabPrecoConc}
                        />
                    </CardBody>
                    <CardFooter>
                        **Taxa Média de Conciliação - últimos 60 dias.
                    </CardFooter>
                </Card>

            </GridContainer>
        </div>
        )}

    };

    
}


export default TrocaPreco;