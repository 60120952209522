import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import auth from '../../services/auth';
import '../../demo/demo.css';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import Button from "../CustomButtons/Button.js";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import "react-datepicker/dist/react-datepicker.css";
import checatoken from '../checatoken';
import swal from 'sweetalert';
import Table from "components/Table/Table.js";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";



export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);


class Top10Prod extends Component {

    constructor (props) {
        super(props)
        this.state = {startDate: new Date(),
                    };
            
              this.handleChange = this.handleChange.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {endDate: new Date(),
                 startDate: new Date(),
                 titulos: {
                    ValorPago: 0,
                    ValorTotal: 0,
                    aPagar: 0,
                    Qtde: 0
                },
                TabelaPag: []
                };
            
              this.handleChangef = this.handleChangef.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);

              this.state.startDate.setHours(this.state.startDate.getDay() - 168);
    }
      
    
    
    handleChange(date) {
            this.setState({ startDate: date })
  
    }
    
    handleChangef(date) {
        this.setState({ endDate: date })

    }

  
      handleSubmit(e) {
        e.preventDefault();
        this.getDadosTabela();
      }

    async getDadosTabela() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/produto/top10', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
            this.setState({ TabelaPag: res.data})
                
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };


    async componentDidMount() {
        await checatoken();
        await this.getDadosTabela();
    };

    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return(<Redirect to="/login" />)
          }
          else
          {

        
        return (<div>
            <GridContainer>
                <GridItem xs={12} sm={4} md={4} >
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>sentiment_satisfied</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Selecione o período</h6>
                        </CardHeader>
                        <CardBody>
                        <GridContainer> 
                        <GridItem xs={12} sm={6} md={6}>
                        <div>
                            <label>Data Inicial: </label>
                                <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="startDate"
                                    id="startDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    input="false"
                                    defaultValue = {this.state.startDate}
                                    onChange={ this.handleChange }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>                                
                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                         <div>
                            <label>Data Final : </label>
                            <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="endDate"
                                    id="endDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    defaultValue = {this.state.endDate}
                                    onChange={ this.handleChangef }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>    
                                {/* <DatePicker
                                name="endDate"
                                id="endDate"
                                selected={ this.state.endDate }
                                onChange={ this.handleChangef}
                                /> */}
                        </div> 
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <br></br>
                        <Button color="primary" round onClick={ this.handleSubmit}>

                                Filtrar
                                
                            </Button>
                        </GridItem>
 


                        </GridContainer>
                        </CardBody>
                        <CardFooter>

                         </CardFooter>
                    </Card>
                </GridItem>
                          
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Top 10 produtos</h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Produto", "Quantidade",  "Valor Total"]}
                            tableData={this.state.TabelaPag}
                        />
                        </CardBody>
                        <CardFooter>
                            Melhores produtos do período - Curva ABC
                        </CardFooter>
                    </Card>
                    </GridItem>                    
                    </GridContainer>

        </div>
        )}

    };


}


export default Top10Prod;