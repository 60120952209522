import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import auth from '../../services/auth';
import '../../demo/demo.css';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import Button from "../CustomButtons/Button.js";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import "react-datepicker/dist/react-datepicker.css";
import checatoken from '../checatoken';
import DateRange from "@material-ui/icons/DateRange";
import {Doughnut} from 'react-chartjs-2';
import swal from 'sweetalert';
import Table from "components/Table/Table.js";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";

export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);

class ContasReceber extends Component {

    constructor (props) {
        super(props)
        this.state = {startDate: new Date(),
                    };
            
              this.handleChange = this.handleChange.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {endDate: new Date(),
                 startDate: new Date(),
                 titulos: {
                    ValorRecebido: 0,
                    ValorTotal: 0,
                    aReceber: 0,
                    Qtde: 0
                },
                TabelaReceb: []
                };
            
              this.handleChangef = this.handleChangef.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);

              this.state.startDate.setMonth(this.state.startDate.getMonth() - 1);
    }
      
   
    
    handleChange(date) {
            this.setState({ startDate: date })
  
    }
    
    handleChangef(date) {
        this.setState({ endDate: date })

    }

  
      handleSubmit(e) {
        e.preventDefault();
        this.getreceberresumo();
        this.getchartreceber();
        this.getDadosTabela();
      }

      async getreceberresumo() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('financeiro/contasrecebercard', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ titulos: res.data });
                        }
        )        
    };      

    async getchartreceber(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('financeiro/contasrecebergraf', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ dataReceberChart :  {
                                labels: res.data.contareceber[0].labels,
                                datasets: res.data.contareceber[1].datasets,
                                options: res.data.contareceber[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }      

    async getDadosTabela() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('/financeiro/contasrecebertab', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
            this.setState({ TabelaReceb: res.data})
                
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };


    async componentDidMount() {
        await checatoken();
        await this.getreceberresumo();
        await this.getchartreceber();
        await this.getDadosTabela();
    };

    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return(<Redirect to="/login" />)
          }
          else
          {

        
        return (<div>
            <GridContainer>
                <GridItem xs={12} sm={4} md={4} >
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>sentiment_satisfied</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Selecione o período</h6>
                        </CardHeader>
                        <CardBody>
                        <GridContainer> 
                        <GridItem xs={12} sm={6} md={6}>
                        <div>
                            <label>Data Inicial: </label>
                                <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="startDate"
                                    id="startDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    input="false"
                                    defaultValue = {this.state.startDate}
                                    onChange={ this.handleChange }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>                                
                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                         <div>
                            <label>Data Final : </label>
                            <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="endDate"
                                    id="endDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    defaultValue = {this.state.endDate}
                                    onChange={ this.handleChangef }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>    
                                {/* <DatePicker
                                name="endDate"
                                id="endDate"
                                selected={ this.state.endDate }
                                onChange={ this.handleChangef}
                                /> */}
                        </div> 
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <br></br>
                        <Button color="primary" round onClick={ this.handleSubmit}>

                                Filtrar
                                
                            </Button>
                        </GridItem>
                        </GridContainer>
                        </CardBody>
                        <CardFooter>

                         </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={8} md={8} >
                <GridContainer>
                <GridItem xs={12} sm={6} md={6} >
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Valor Total</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                            {numberFormat(this.state.titulos.ValorTotal)}  
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                                <DateRange />
                                Valor Total no período
                        </CardFooter>
                    </Card>              
                </GridItem>                
                <GridItem xs={12} sm={6} md={6} >
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Valor Recebido</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                            {numberFormat(this.state.titulos.ValorRecebido)}  
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                                <DateRange />
                                Valor recebido no Período
                        </CardFooter>
                    </Card>              
                </GridItem>                
                <GridItem xs={12} sm={6} md={6} >
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <CardIcon color="danger">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Total a Receber</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                            {numberFormat(this.state.titulos.aReceber)}  
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                                <DateRange />
                                Valor a receber no período
                        </CardFooter>
                    </Card>              
                    </GridItem>                
                    <GridItem xs={12} sm={6} md={6} >
                    <Card>
                        <CardHeader color="primary" stats icon>
                            <CardIcon color="primary">
                                <Icon>show_chart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Qtde de Titulos</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                                {this.state.titulos.Qtde} 
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                                <DateRange />
                                Quantidade de titulos no período
                        </CardFooter>
                    </Card>              
                    </GridItem>                
                    </GridContainer>
            </GridItem>                
            </GridContainer>
            <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Recebimento por Categoria  </h4>
                        </CardHeader>
                        <CardBody>
                          <Doughnut data={this.state.dataReceberChart} height={255}   options={{legend: { display: true}}}/>
                        </CardBody>
                        <CardFooter>
                        Recebimento por Categoria
                        </CardFooter>
                    </Card>
                </GridItem>                    
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Recebimento por Categoria </h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Categoria", "Valor Total"]}
                            tableData={this.state.TabelaReceb}
                        />
                        </CardBody>
                        <CardFooter>
                                 Recebimento de titulos por Categoria
                        </CardFooter>
                    </Card>
                    </GridItem>                    
                    </GridContainer>

        </div>
        )}

    };


}


export default ContasReceber;