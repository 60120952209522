import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import auth from '../../services/auth';
import '../../demo/demo.css';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import Button from "../CustomButtons/Button.js";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import DateRange from "@material-ui/icons/DateRange";
import swal from 'sweetalert';
import "react-datepicker/dist/react-datepicker.css";
import checatoken from '../checatoken';
import Table from "components/Table/Table.js";
import {Doughnut} from 'react-chartjs-2';
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Line} from 'react-chartjs-2';

export const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  export const numberFormat2 = (value) =>
  new Intl.NumberFormat('pt-BR').format(value);

class CompVendaProduto extends Component {

    constructor (props) {
        super(props)
        this.state = {startDate: new Date(),
                      
                    };
            
              this.handleChange = this.handleChange.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {endDate: new Date(),
                 startDate: new Date(),
                 checkedB: true,
                 checkedA: true,
                 DataGraficoLinhaProd: [],
                 ProdVenda: {
                    QtdeItens: 0,
                    ValorSaida: 0,
                    ValorCompra: 0,
                    Imposto:0,
                    ComissaoRet:0,
                    TicketMedio:0,
                    QtdeCupons:0,
                    MediaItensCupom:0,
                    Lucro:0,
                    Cortesia:0   
                  },
                  TabelaVenda: []
                    };
            
              this.handleChangef = this.handleChangef.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);
    }
      
    async GrupoDia () {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('unid/GraficoLinhaProd', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ DataGraficoLinhaProd :  {
                                labels: res.data.GraficoLinhaGrupo[0].labels,
                                datasets: res.data.GraficoLinhaGrupo[1].datasets,
                                options: res.data.GraficoLinhaGrupo[2].options
                                                        } 
                                                    })                            
                        }
        )   
        
    };

    async getgrafprodvenda(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('produto/prodchartvendagrupo', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate, ProdLoja: this.state.checkedB, ProdPista: this.state.checkedA}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ datalbar :  {
                                labels: res.data.prodgrupo[0].labels,
                                datasets: res.data.prodgrupo[1].datasets,
                                options: res.data.prodgrupo[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }
   
    
    handleChange(date) {
            this.setState({ startDate: date })
  
    }
    
    handleChangef(date) {
        this.setState({ endDate: date })

    }

  
      handleSubmit(e) {
        e.preventDefault();
        this.getTitulo();
        this.getDadosTabela();
        this.getgrafprodvenda();
        this.GrupoDia();
       
      }


      handleChangeC = event =>
             this.setState({checkedB: event.target.checked});
          
        //this.setState({ checkedB: vboolean })


     handleChangeCA = event =>
         this.setState({checkedA: event.target.checked});



    async componentDidMount() {
        await checatoken();
        await this.getTitulo();
        await this.getDadosTabela();
        await this.getgrafprodvenda();
        await this.GrupoDia();
        // await this.DashboardBarChart();
//        swal("Seja bem-vindo!", "Você está visualizando  "+this.state.data.unidOpe+" !", "success");        
//        await this.getVendeuHoje();
    };


    async getTitulo(){
        const chave = localStorage.getItem('lukos-token');
        const response = await auth.get('produto/resumo', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate, ProdLoja: this.state.checkedB, ProdPista: this.state.checkedA}
        });
        this.setState({ ProdVenda: response.data });
        console.log(this.state.ProdVenda);
    }

    async getDadosTabela() {
        const chave = localStorage.getItem('lukos-token');
        this.setState({ TabelaVenda: []})
        await auth.get('/produto/tabelavenda', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate, ProdLoja: this.state.checkedB, ProdPista: this.state.checkedA}
        }).then( res => {
            this.setState({ TabelaVenda: res.data})
                
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };

    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return(<Redirect to="/login" />)
          }
          else
          {

        
        return (<div>
            <GridContainer>
                <GridItem xs={12} sm={4} md={4} >
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>sentiment_satisfied</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Selecione o período</h6>
                        </CardHeader>
                        <CardBody>
                        <GridContainer> 
                        <GridItem xs={12} sm={6} md={6}>
                        <div>
                            <label>Data Inicial: </label>
                                <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="startDate"
                                    id="startDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    input="false"
                                    defaultValue = {this.state.startDate}
                                    onChange={ this.handleChange }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>                                
                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                         <div>
                            <label>Data Final : </label>
                            <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="endDate"
                                    id="endDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    defaultValue = {this.state.endDate}
                                    onChange={ this.handleChangef }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>    
                                {/* <DatePicker
                                name="endDate"
                                id="endDate"
                                selected={ this.state.endDate }
                                onChange={ this.handleChangef}
                                /> */}
                        </div> 
                        
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                        <FormControlLabel
                                control={
                                <Checkbox
                                    checked={this.state.checkedB}
                                    onChange={this.handleChangeC}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label="Produtos de Loja"
                            />
                        <FormControlLabel
                                control={
                                <Checkbox
                                    checked={this.state.checkedA}
                                    onChange={this.handleChangeCA}
                                    name="checkedA"
                                    color="primary"
                                />
                                }
                                label="Produtos de Pista"
                            />

                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                            <br></br>
                        <Button color="primary" round onClick={ this.handleSubmit}>

                                Filtrar
                                
                            </Button>
                        </GridItem>
                        </GridContainer>
                        </CardBody>
                        <CardFooter>

                         </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={8} md={8} >
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6} >
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>attach_money</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Total Vendido</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                                {numberFormat(this.state.ProdVenda.ValorSaida)} 
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                                <DateRange />
                                Valor total vendido
                        </CardFooter>
                    </Card>              
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} >                    
                    <Card>
                        <CardHeader color="primary" stats icon>
                            <CardIcon color="primary">
                                <Icon>add_shopping_cart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Quantidade de Itens</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                                {numberFormat2(this.state.ProdVenda.QtdeItens)} 
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                                <DateRange />
                                Total de itens vendidos
                        </CardFooter>
                    </Card>              
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} >                    
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <CardIcon color="danger">
                                <Icon>add_shopping_cart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Valor custo</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                               R$ {numberFormat2(this.state.ProdVenda.ValorCompra)} 
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                                <DateRange />
                                Valor custo dos produtos
                        </CardFooter>
                    </Card>              
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} >                    
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <Icon>add_shopping_cart</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Lucro</h6>
                            <h3 className='makeStyles-cardTitle-91'>
                               R$ {numberFormat2(this.state.ProdVenda.Lucro)} 
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                                <DateRange />
                                Lucro obtido nas vendas
                        </CardFooter>
                    </Card>              
                    </GridItem>

                    </GridContainer>
                    </GridItem>
                   
                    <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Vendas por Grupo de produtos  </h4>
                        </CardHeader>
                        <CardBody>
                          <Doughnut data={this.state.datalbar} height={200}   options={{legend: { display: true}}}/>
                        </CardBody>
                        <CardFooter>
                               Venda de produtos por grupo.
                        </CardFooter>
                    </Card>
                </GridItem>   
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Grupo por dia  </h4>
                        </CardHeader>
                        <CardBody>
                        <div>
                        <Line data={this.state.DataGraficoLinhaProd}  height={200}   options={{legend: { display: true}}} />
                    </div>
                        </CardBody>
                        <CardFooter>
                               valores de referência em quantidade 
                        </CardFooter>
                    </Card>
                </GridItem>   
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Vendas por Grupo de produtos  </h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Grupo", "Valor Venda", "Custo Total", "Qtde Vendido", "Lucro"]}
                            tableData={this.state.TabelaVenda}
                        />
                        </CardBody>
                        <CardFooter>
                               Venda de produtos por grupo.
                        </CardFooter>
                    </Card>
                </GridItem>                         


            </GridContainer>

        </div>
        )}

    };


}


export default CompVendaProduto;