import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import auth from '../../services/auth';
import '../../demo/demo.css';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import Button from "../CustomButtons/Button.js";
import {Bar} from 'react-chartjs-2';

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import "react-datepicker/dist/react-datepicker.css";
import checatoken from '../checatoken';
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";

class CompComparativoVendas extends Component {

    constructor (props) {
        super(props)
        this.state = {startDate: new Date(),
                    };
            
              this.handleChange = this.handleChange.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {endDate: new Date(),
                 startDate: new Date()
                    };
            
              this.handleChangef = this.handleChangef.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);

              this.state.startDate.setMonth(this.state.startDate.getMonth() - 6);
    }
      
   
    
    handleChange(date) {
            this.setState({ startDate: date })
  
    }
    
    handleChangef(date) {
        this.setState({ endDate: date })

    }

  
      handleSubmit(e) {
        e.preventDefault();
        this.getCompCombLitros();
        this.getCompCombValor();
        this.getCompProdQtde();
        this.getCompProdValor();

      }

      async getCompCombLitros(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('produto/comparativocomblitros', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ datalbar :  {
                                labels: res.data.CompCombLitros[0].labels,
                                datasets: res.data.CompCombLitros[1].datasets,
                                options: res.data.CompCombLitros[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }      

    async getCompCombValor(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('produto/comparativocombvalor', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ dataValor :  {
                                labels: res.data.CompCombValor[0].labels,
                                datasets: res.data.CompCombValor[1].datasets,
                                options: res.data.CompCombValor[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }      

    async getCompProdQtde(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('produto/comparativoprodqtde', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ dataProdQtde :  {
                                labels: res.data.CompProdQtde[0].labels,
                                datasets: res.data.CompProdQtde[1].datasets,
                                options: res.data.CompProdQtde[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }      

    async getCompProdValor(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('produto/comparativoprodValor', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ dataProdValor :  {
                                labels: res.data.CompProdValor[0].labels,
                                datasets: res.data.CompProdValor[1].datasets,
                                options: res.data.CompProdValor[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }      




    async componentDidMount() {
        await checatoken();
        await this.getCompCombLitros();
        await this.getCompCombValor();
        await this.getCompProdQtde();
        await this.getCompProdValor();
    };

    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return(<Redirect to="/login" />)
          }
          else
          {

        
        return (<div>
            <GridContainer>
                <GridItem xs={12} sm={4} md={4} >
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>sentiment_satisfied</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Selecione o período</h6>
                        </CardHeader>
                        <CardBody>
                        <GridContainer> 
                        <GridItem xs={12} sm={6} md={6}>
                        <div>
                            <label>Data Inicial: </label>
                                <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="startDate"
                                    id="startDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    input="false"
                                    defaultValue = {this.state.startDate}
                                    onChange={ this.handleChange }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>                                
                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                         <div>
                            <label>Data Final : </label>
                            <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="endDate"
                                    id="endDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    defaultValue = {this.state.endDate}
                                    onChange={ this.handleChangef }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>    
                                {/* <DatePicker
                                name="endDate"
                                id="endDate"
                                selected={ this.state.endDate }
                                onChange={ this.handleChangef}
                                /> */}
                        </div> 
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <br></br>
                        <Button color="primary" round onClick={ this.handleSubmit}>

                                Filtrar
                                
                            </Button>
                        </GridItem>
                        </GridContainer>
                        </CardBody>
                        <CardFooter>

                         </CardFooter>
                    </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Venda de Combustivel Litros.  </h4>
                        </CardHeader>
                        <CardBody>
                        <Bar data={this.state.datalbar} options={{legend: { display: false}}} />
                        </CardBody>
                        <CardFooter>
                               Venda de Combustivel Litros.
                        </CardFooter>
                    </Card>
                </GridItem>            
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Venda de Combustivel Valor </h4>
                        </CardHeader>
                        <CardBody>
                        <Bar data={this.state.dataValor} options={{legend: { display: false}}} />
                        </CardBody>
                        <CardFooter>
                               Venda de Combustivel em reais.
                        </CardFooter>
                    </Card>
                    </GridItem>            
                    <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Produtos Quantidade </h4>
                        </CardHeader>
                        <CardBody>
                        <Bar data={this.state.dataProdQtde} options={{legend: { display: false}}} />
                        </CardBody>
                        <CardFooter>
                               Venda de produtos quantidade.
                        </CardFooter>
                    </Card>
                    </GridItem>            
                    <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Produtos Valor </h4>
                        </CardHeader>
                        <CardBody>
                        <Bar data={this.state.dataProdValor} options={{legend: { display: false}}} />
                        </CardBody>
                        <CardFooter>
                               Venda de produtos em reais.
                        </CardFooter>
                    </Card>
                    </GridItem>            
            </GridContainer>

        </div>
        )}

    };


}


export default CompComparativoVendas;