import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import auth from '../../services/auth';
import '../../demo/demo.css';
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardIcon from "../Card/CardIcon.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import Button from "../CustomButtons/Button.js";
import {Line} from 'react-chartjs-2';

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import "react-datepicker/dist/react-datepicker.css";
import checatoken from '../checatoken';
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import swal from 'sweetalert';
import Table from "components/Table/Table.js";

class resmensal extends Component {

    constructor (props) {
        super(props)
        this.state = {startDate: new Date(),
                    };
            
              this.handleChange = this.handleChange.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {endDate: new Date(),
                 startDate: new Date(),
                 TabrestMes: [],
                 TabResultadoSangrias: [],
                 TabResultadoOpe: [],
                    };
            
              this.handleChangef = this.handleChangef.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);

              this.state.startDate.setMonth(this.state.startDate.getMonth() - 3);
    }
      
   
    
    handleChange(date) {
            this.setState({ startDate: date })
  
    }
    
    handleChangef(date) {
        this.setState({ endDate: date })

    }

  
      handleSubmit(e) {
        this.setState({TabResultadoSangrias: []})
        e.preventDefault();
        this.getCompCombLitros();
        this.getDadosTabela();
        this.getDadosTabelaOpe();
        this.getResultadoope();
        this.getDadosSangrias();
      }

      async getCompCombLitros(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('financeiro/ResultadoMesfinanceiro', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
                            console.log(res.data)
                            this.setState({ dataLine :  {
                                labels: res.data.ResultadoMesFinanceiro[0].labels,
                                datasets: res.data.ResultadoMesFinanceiro[1].datasets,
                                options: res.data.ResultadoMesFinanceiro[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }      

    async getResultadoope(){
        const chave = localStorage.getItem('lukos-token');
        await auth.get('financeiro/Resultadoope', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {   
                            console.log(res.data)
                            this.setState({ dataLine2 :  {
                                labels: res.data.Resultadoope[0].labels,
                                datasets: res.data.Resultadoope[1].datasets,
                                options: res.data.Resultadoope[2].options
                                                        } 
                                                    })                            
                        }
        )        
    }      

    async getDadosTabela() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('financeiro/ResultadoMesfinanceirotab', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
            this.setState({ TabrestMes: res.data})
                
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };    

    async getDadosTabelaOpe() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('financeiro/Resultadoopetab', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( res => {
            this.setState({ TabResultadoOpe: res.data})
            }
        )
        .catch(function (error) {
                swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };    

    async getDadosSangrias() {
        const chave = localStorage.getItem('lukos-token');
        await auth.get('financeiro/ResultadoSangrias', {
            headers: {token: chave},
            params:   {dataIni : this.state.startDate, dataFim: this.state.endDate}
        }).then( 
            res => {
                this.setState({ TabResultadoSangrias: res.data})
            }
        )
        .catch(function (error) {
              //  swal("OPS!! Sem dados", "Não tem dados no período indicado, tente mudar as datas no filtro", "warning");
            })  
    };    

    async componentDidMount() {
        await checatoken();
        await this.getCompCombLitros();
        await this.getDadosTabela();
        await this.getDadosTabelaOpe();
        await this.getResultadoope();
        await this.getDadosSangrias();
    };

    render() {
        const isLogged = !!localStorage.getItem('lukos-token');
        if (isLogged === false) {
            return(<Redirect to="/login" />)
          }
          else
          {

        
        return (<div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>sentiment_satisfied</Icon>
                            </CardIcon>
                            <br></br>
                            <h6>Selecione o período</h6>
                        </CardHeader>
                        <CardBody>
                        <GridContainer> 
                        <GridItem xs={12} sm={4} md={4}>
                        <div>
                            <label>Data Inicial: </label>
                                <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="startDate"
                                    id="startDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    input="false"
                                    defaultValue = {this.state.startDate}
                                    onChange={ this.handleChange }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>                                
                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                         <div>
                            <label>Data Final : </label>
                            <FormControl fullWidth>
                                    <Datetime
                                    //   timeFormat={false}
                                    //   inputProps={{ placeholder: "Date Picker Here" }}
                                    timeFormat={false}
                                    name="endDate"
                                    id="endDate" 
                                    locale="pt-br"
                                    dateFormat="DD/MM/yyyy"
                                    closeOnSelect="true"
                                    defaultValue = {this.state.endDate}
                                    onChange={ this.handleChangef }
                                    inputProps={{ placeholder: "Date Picker Here" }}
                                    />
                                </FormControl>    
                                {/* <DatePicker
                                name="endDate"
                                id="endDate"
                                selected={ this.state.endDate }
                                onChange={ this.handleChangef}
                                /> */}
                        </div> 
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2}>
                            <br></br>
                        <Button color="primary" round onClick={ this.handleSubmit}>

                                Filtrar
                                
                            </Button>
                        </GridItem>
                        </GridContainer>
                        </CardBody>
                        <CardFooter>

                         </CardFooter>
                    </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Resultado Financeiro mensal.  </h4>
                        </CardHeader>
                        <CardBody>
                        <Line data={this.state.dataLine} options={{legend: { display: false}}} />
                        </CardBody>
                        <CardFooter>
                               Total de contas a Pagar/Receber no período.
                        </CardFooter>
                    </Card>
                </GridItem>   
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className="makeStyles-cardTitleWhite-87">Resultado Financeiro por Mês </h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["DataRef", "Total Receber", "Total Pagar", "Resultado"]}
                            tableData={this.state.TabrestMes}
                        />
                        </CardBody>
                        <CardFooter>
                            Total de contas a Pagar/Receber no período.
                        </CardFooter>
                    </Card>
                    </GridItem>                               

                    <GridItem xs={12} sm={8} md={8}>
                    <Card>
                        <CardHeader color="success">
                            <h4 className="makeStyles-cardTitleWhite-87">Resultado Operacional </h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["DataRef", "$ Vendido", "$ Custo", "Lucro Aprox.", "Total Sangrias", "Total Taxas", "Total Receber", "Despesas Operacional",  "Resultado"]}
                            tableData={this.state.TabResultadoOpe}
                        />
                        </CardBody>
                        <CardFooter>
                            Resultado Operacional do periodo
                        </CardFooter>
                    </Card>
                    </GridItem>    
                    <GridItem xs={12} sm={4} md={4}>
                    <Card>
                        <CardHeader color="success">
                            <h4 className="makeStyles-cardTitleWhite-87">Resultado Operacional.  </h4>
                        </CardHeader>
                        <CardBody>
                        <Line data={this.state.dataLine2} options={{legend: { display: false}}} />
                        </CardBody>
                        <CardFooter>
                               Resultado Operacional do Período
                        </CardFooter>
                    </Card>
                </GridItem>                                                  
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="warning">
                            <h4 className="makeStyles-cardTitleWhite-87">Resultado Sangrias</h4>
                        </CardHeader>
                        <CardBody>
                        <Table
                            tableHeaderColor="warning"
                            tableHead={["DataRef","Meio Pagamento", "Cartão", "$ Total Sangria", "$ Taxa", "% Taxa",  "Total Receber", "Representatividade",  "Recebido"]}
                            tableData={this.state.TabResultadoSangrias}
                        />
                        </CardBody>
                        <CardFooter>
                            Resultado Sangrias do periodo
                        </CardFooter>
                    </Card>
                </GridItem>                                                  

            </GridContainer>

        </div>
        )}

    };


}


export default resmensal;